import rule from "@/i18n/rule";

export default {
    nav: {
        // 导航栏
        welfare: "welfare",
        mySkins: 'Items',
        myWelfare: 'benefits.',
        myReward: 'Commission',
        information: "个人中心",
        historicalBilling: 'Transactions',
        login: "log in",
        logout: "Log out",
        blindBox: 'Cases',
        rollRoom: "rolls",
        dream: "percent case",
        battle: "case Battle",
        bomb: "bomb disposal",
        refill: "Refill",
        // 登陆弹窗
        loginTitle: 'We need to make sure you can use csbox, please confirm:',
        my18: 'I\'m 18 years old.',
        agree: "I have read and agree to",
        usrAgreement: 'User Agreement',
        privacyAgreement: 'Privacy Agreement',
        antiMoneyLaundering: 'Anti-Money Laundering Agreement',
        // 电脑版footer内容
        openCases: 'open case',
        users: 'users',
        online: 'online',
        footerText1: "Blind boxes are played in a variety of ways, and  extraction is safe and convenient!",
        // 手机版侧边栏
        games: "game",
        preferential: "favorable",
        free: "free",
        recharge: "Refill",
        cdk: "CDK",
        other: "other",
        QQ: "QQ",
        custServ: "customer service",
        App: "App",
        glblMusicEnabled: "Enabled sound effects",
        soundEffectsDisabled: "Disabled sound effects",
        questions: "FAQ ",
        aboutUs: "About",
        pleaseEntrANickname: "Please enter a nickname",
        pleaseEntrYourEmailAddr: "Please enter your cell phone number",
        pleaseEntrTheVerificationCd: "Please enter the verification code",
        obtainVerificationCd: "Get code",
        pleaseOutputTheInptPsw: "Please enter your password",
        pleaseConfThePsw: "Please enter the password again",
        pleaseEntrTheInvtCd: "Please enter invitation code (optional)",
        noSteam: "Don't have STEAM account?",
        emailLogin:"Mobile Login",
        passwordLogin:"password login",
        verificationCdLogin:"captcha Login",
        register: "register",
        steamLogin: "steam login",
        forgetPsw: "Forgot your password?",
        conf: "confirmed",
        retrievePsw: "Retrieve password",
        ruleDream:"Percent case rule",
        exchange: "Exchange",
        inptCdk: "Please enter CDK passphrase",
        redemptionSuccessful: "Redemption successful, A-coin"
    },
    blindBox: {
        //开箱页面
        open: "open",
        buy: "purchase",
        bestDrp: "Recent Drops",
        boxContains: "Case contains",
        buyTips1: "OOPS, YOUR BALANCE IS TOO LOW!",
        mbrDrp:"会员掉落",
        anchorDrp:"主播掉落",
    },
    roll: {
        ofcl: "OFFICIAL",
        anchor: "anchor ",
        iParticipatedIn: "my rolls",
        haveInHand: "Active rolls",
        itsOvr: "Completed rolls",
        participated: "engaged",
        viewingRooms: "View Roll",
        joinNow: "join",
        deadline: "end time",
        totVal: "total value",
        items: "Items",
        players: "Players",
        //roll详情
        countdownToLottery: "Countdown to results",
        prtpConditions: "Conditions of participation",
        includingPrizes: "Prizes included",
        participatingUsers: "PARTICIPANTS",
        awrdLst: "WINNERS",
        joinRm: "join"
    },
    dream: {
        chooseAccessories: "Select item",
        probability: "probability ",
        upg: "Dreams",
        upgTips: "*Dreams probability ranges from {num1}% to {num2}%",
        //追梦选择饰品弹窗
        inputPlaceholder1: "Search Case...",
        minPrc:'最低价',
        maxPrc:'最高价',
    },
    battle: {
        //对战列表
        allBattles: "All battles",
        rankingLst: "ranking list",
        historicalRecords: "historical record",
        myRecords: "My records",
        //创建对战
        addBox: "add case",
        robot: "robot",
        combatCost: "Battle’s cost",
        createBattle: "Create Battle",
        chooseAnItem: "Select item",
        incorrectNumberOfPeople: "Incorrect number of people",
        inputPlaceholder1: "Search Case...",
        thisMorning: "this morning",
        thisAfternoon: "this afternoon",
        selected: "Selected:{num}",
        coVal: "Total cost",
        //对战详情
        ready: "ready",
        drawnJewelry: "Winning the trinket",
        obtainJewelry: "Acquired trinket",
        //排行榜
        today: "today",
        yesterday: "yesterday",
        //对战结束弹窗
        battleIsOvr: "The end of the battle.",
        winner: "winner",
        oneMoreRound: "Create Same Duel",
        confJoin: "Is it confirmed to join?"
    },
    information: {
        //个人中心首页
        invtCd: "invitation code",
        bal: 'balance ',
        rchg: "Refill ",
        orderNumber: 'order number',
        transactionLnk: "STEAM Trading Links",
        steamLinkTips1: "1、Trading link once bound can not be replaced, please be careful operation, the same link update token is not limited.",
        steamLinkTips2: "2、Please do not bind other people's steam trading links",
        steamInputPlaceholder1: "Enter your STEAM transaction link",
        obtain: "gain",
        saveAddr: "Save Address",
        save: "Save",
        mySkins: 'Items',
        myWelfare: 'Benefits',
        rchgDetails: "Payment history",
        incomeDetails: "income and expenditure",
        myReward: 'My Commissions',
        // 我的物品页面
        retrieving: "Being Withdraw ",
        retrieveRecords: "Withdraw history",
        numOrnaments: "Total {num} of ornaments",
        obtainingTm: "Acquisition time",
        val: "value",
        selectedNum: "Selected {num} items",
        retrieve: "Withdraw",
        chooseABox: "Go to the selection box to get some",
        noItems: "Your inventory is empty",
        noRecIsCur: "No current record",
        //佣金页面
        generalizationCd: "Promo code",
        cpy: "copy",
        promLnk: "Promotional Links",
        partnerRules: "terms & conditions and site rules",
        totNumOfInvitations: "Total number of invitees",
        effectiveUsers: "active user",
        accumRewards: "Cumulative rewards",
        rewardRatio: "Incentive ratio",
        lvlDsc: "Grade Description",
        invtRec: "Record of invitations",
        date: "date",
        invitee: "invitee",
        usrRchg: "User Recharge",
        myRewards: "My Rewards",
        rewardLvl: "Reward level",
        invitedUsrRchg: "Invited Users Recharge",
        promAndAcq: "Promoted users will receive",
        incmStmt: "Introduction to earnings"
    },
    confirm: {
        success: "success",
        warning: "warn",
        error: "error",
        noDataAvailable: "No data",
        //老虎机抽奖结果弹窗
        decompose: "sell",
        decomposeAll: "sell all",
        cont: "Keep unboxing.",
        //confirm提示弹窗
        confDecomposition: "Confirmed to sell {num} pieces of equipment?",
        common: "total",
        cancel: "cancel",
        confirm: "confirm",
        confDec: "Confirm decomposition?",
        confOpn: "Is it confirmed open?" ,
        conf: "Confirmation or not?" ,
        //msg提示弹窗
        decomposeSuccess: "Item has been successfully sold!",
        theMinimumProbabilityIs: "The probability is minimized as {num}",
        notLoggedIn: "not logged",
        noJewelrySelected: "unselected item",
        agreeToATreaty: "Please read and agree to the following treaty",
        copySuccess: "Copy Success",
        copyError: "copy failed",
        pleaseFollow: "Retrieving. Please wait.",
        notYetOpn: "not yet open",
        glblMusic: "Please turn on all sounds first",
        amtCannotBeZero: "The amount cannot be 0",
        pswModSuccessful: "Password changed successfully",
        loginSuccessful: "Login Success",
        loginWasSuccessful: "Registration successful, please log in!",
        emailFmtErr: "Wrong format of cell phone number",
        vSntSuccess: "Verification code sent successfully",
        pswInconsistency: "Password inconsistency",
        entrTheVerificationCd: "Please enter the verification code",
        theMinPswLenIs6: "Minimum password length is 6",
        pleaseEntrANickname: "Please enter a nickname",
    },
    //常用的
    common: {
        //单位
        day: 'day',
        hh: "hour",
        mm: 'minute',
        ss: "second",
        piece: "piece",
        // title常用
        back: "back",
        gameRules: "game rules",
        //表格常用
        players: "player",
        price: "price",
        reset: "reset",
        round:"round",
        cases: "cases",
        value: "value",
        actions: "actions",
        watch: "watch",
        join: "join",
        ranking: "Ranking",
        bestDrp: "Best Drops",
        date: "date",
        st: "state",
        purp: "uses",
        amtOfMoney: "amounts",
        allElection: "Select All",
        typ: "type",
        numericalVal: "numericValue",
    },
    incomeType: {
        //个人中心收支type
        whole: "all",
        incm: "incomes",
        expenditure: "expenditures",
        purCurr: "money purchase",
        recovery: "recall",
        unpacking: "open",
        commission: "commission",
        redEnvelopes: "rewards",
        war: "battle",
        shoppingMallPur: "Purchase at the mall",
        chasingDreams: "dreams",
        coinPurGft: "Buy Coins Giveaway",
        cshWdrl: "withdraw funds",
        rlRm: "rolls",
        regGifts: "Registration Gift",
        addRewards: "additional bonus",
        redEnvelopeReward: "Red Packet Bonus",
        luckyTurntable: "Lucky turntable",
        bkOffcDistribution: "Back-office issuance",
        skinSynthesis: "skin synthesis",
        sgnInReward: "Sign in reward",
        newDreamChasing: "New Dream Chasing",
        unpackingKing: "Unpacking King",
        skydiverHd: "skydiving",
        inviteRebate: "Invite Rebate",
        taskRewards: "Mission Rewards",
    },
    provably: {
        provably: "Fairness Verification",
        provablyDsc: "Fairness validation description",
        text1: "1. Players generate a roll point each time they play, and the corresponding roll point gets the corresponding skin.",
        text2: "2, open box chase dream arena three play each set of user seed and public hash, module common for example: open box block a box and b box in the rl point there is no difference, only to get the skin there is a difference.",
        text3: "3. The encrypted values involved in the operation are as follows:",
        text4: "4, we provide the open source code and test platform of this roll point algorithm, friends who know programmers can operate stand-alone to see if the results are consistent in the case of unrelated web servers.",
        clientSeed: "userSeed",
        publicHash: "publicHash",
        round: "rounds",
        secretHash: "secretHash",
        secretSalt: "secret salt",
        createTime: "createTime",
        enterClientSeed: "Please enter a user seed",
        enterPublicHash: "Please enter the public hash",
        enterSecretHash: "Please enter the secret hash",
        enterSecretSalt: "Please enter the secret salt",
        enterRound: "Please enter the number of rounds",
        p1: "That is to say, there are two values that the user can change by himself, and these two values are involved in operations that can change the result/roll point. For example: personal seed default 1. manually change to 2 after changing the roll point result for 10086 other parameters remain unchanged, and then go to use the tool to verify the results of 1, you will find that the seed for the case of 1 the result is not 10086 ",
        p2: "In the example, after changing the seed or public hash, you can see the secret hash and SECRET SALT of the previous game, and then you can go to verify whether the previous result is correct. It is also possible to take the previous values and measure whether the probability distribution is reasonable under larger rounds (e.g. 10w times).",
        opnSrcAddr: "Open source address",
        download: "Download Offline Validator",
        curSeed: "Current Seeds",
        preSeed: "previousServerSeed",
        chg: "change",
        reset: "Reset",
        oneClkCpy: "OneClickCopy",
        validator: "Fairness Validator",
        attachment1: "(changeable at will)",
        attachment2: "(Resettable)",
        attachment3: "(Increases with number of games played)",
        resetDisp:"(show after resetting public hash)",
        legitimateSeed: "Please enter a legal seed",
        entrANewSeed: "Input new seed",
        seedReg: "Three characters (or more) of English letters, numbers or Chinese characters",
        oneClkPaste: "OneClickPaste",
        rlPnt: "roll point",
        waitVerification: "Waiting for verification",
    },
    privacyClause: {
        usrAgreement: rule.usrAgreement.en,
        privacyAgreement: rule.privacyAgreement.en,
        aboutUs: rule.aboutUs.en,
        questions: rule.questions.en,
        ruleDream: rule.ruleDream.en,
        ruleBattle: rule.ruleBattle.en
    },
    217: 'After the accumulated deposit reaches {num} yuan, you can enjoy a free lottery draw every day. The more accumulated deposits, the better the prizes you will get.',
    218: 'Enjoy {num}% bonus when you recharge next time',
    // 十二星座
    238: "Aries",
    239: "Taurus",
    240: "Gemini",
    241: "Cancer",
    242: "Leo",
    243: "Virgo",
    244: "Libra",
    245: "Scorpio",
    246: "Sagittarius",
    247: "Capricorn",
    248: "Aquarius",
    249: "Pisces",
    250: "The Twelve Constellations",
    251: "Constellation Backpack",
    252: "Starting",
    253: "Recycling price of sets",
    254: "Recycling set",
    255:"Please enter the number of breakdowns",
    256:"Not enough for one set, can't break it down!",
    257:"Happy to take it.",
}
