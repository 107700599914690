
// 自定义指令,点击自身以外的元素会触发
export default {
    // 插件的 install 方法
    install(app) {
        app.directive('click-outside', {
            mounted(el, binding) {
                // 在绑定时，添加事件监听器
                const handler = (event) => {
                    if (!(el === event.target || el.contains(event.target))) {
                        binding.value();
                    }
                };
                // 将事件处理程序存储在元素上，以便在解绑时移除
                el.__clickOutsideHandler = handler;
                document.body.addEventListener('click', handler);
            },
            beforeUnmount(el) {
                // 在解绑前，移除事件监听器
                document.body.removeEventListener('click', el.__clickOutsideHandler);
            },
        });
    },
};