<!--
时间:2023/8/21/021
用法:整体布局
-->

<template>
  <div class='layout' id="layout">
    <Nav/>
<!--    <LeftNav/>-->
    <RightNav/>
    <div class="layoutView">
<!--      <NavSwiper v-if="windowWidth > 992  ||  path.includes($route.path)"/>-->
      <router-view class="routerView"></router-view>
      <Footer v-if="windowWidth > 992  ||  path.includes($route.path)"/>
    </div>
    <TabBar />
  </div>
</template>

<script>
import Nav from "@/layout/HeaderNav.vue";
import NavSwiper from "@/layout/NavSwiper.vue";
import LeftNav from "@/layout/LeftNav.vue";
import Footer from "@/layout/Footer.vue";
import TabBar from "@/layout/TabBar.vue";
import RightNav from "@/layout/RightNav";
export default {
  name: "Layout",
  components: {
    RightNav,
    TabBar,
    NavSwiper,
    Nav,
    LeftNav,
    Footer
  },
  data() {
    return {
      windowWidth:document.documentElement.clientWidth,
      path:['/','/homes']
    }
  },
  methods: {},
}
</script>

<style scoped lang="scss">

#layout {
  min-height: 100%;
  position: relative;

  .layoutView {
    //padding-left: var(--window-leftNav-width);
    //padding-top: var(--window-headerNav-height);

    position: relative;

    @media screen and (max-width: $windowSize-md){
      //padding: var(--window-headerNav-height) 0 var(--mobil-tabbar-height) 0;
    }
  }
}


</style>
