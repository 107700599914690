import { createI18n } from 'vue-i18n'
import {defaultLanguage} from "@/common/config";
//中文
import zh_CN from './zh_CN'
//英文
import en_US from './en_US'
import tr_TR from './tr_TR'

const i18n = createI18n({
   // 如果本地有语言标识就采用本地，没有就根据浏览器语言默认标识显示语言
   locale: localStorage.getItem('language') || defaultLanguage,
   messages:{
      zh_CN,
      en_US,
      tr_TR,
   },
});
export default i18n;
