import {createApp} from 'vue'
import Dialog from '@/components/Message/Message.vue'

export const createDialog = (option = {}) => {
   /* let aa = document.getElementById('messageBox')
    console.log(aa)*/
    let mountNode = document.createElement('div')
    mountNode.id = 'messageBox'
    const Instance = createApp(Dialog, {
        ...option,
        close: () => {
            Instance.unmount(mountNode);
            document.body.removeChild(mountNode);
        }
    })
    document.body.appendChild(mountNode)
    Instance.mount(mountNode)
}
const type = ['success','error','warn']
for (let i = 0; i < type.length; i++) {
    createDialog[type[i]] = (message,option) => {
        if(typeof message === "string"){
            createDialog({
                type: type[i],
                text: message,
                ...option
            });
        }else {
            createDialog({
                type: type[i],
                ...message
            });
        }
    }
}

export default {
    install(app) {
        app.config.globalProperties.$Msg = createDialog
    }
};

