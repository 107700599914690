import {createStore} from 'vuex'
import {getUserInfo} from "@/axios/auth";
import Decimal from "decimal.js";
import { createDialog } from '@/components/Message'
import i18n from "@/i18n";

export default createStore({
    state: {
        // language: 'EN',//当前语言
        userInfo: {},//用户的基本信息
        audioState: JSON.parse(localStorage.getItem('audioState')) || {audio: true, video: true},//视频音频是否打开
        creatBattle: {},//ws推的新创建的房间
        joinBattle: {},//ws推的新加入房间
        rollEnd: {},//ws推的roll开奖
        countChangeQueue: {},//网站footer里面的在线人数等
        userClick: false,//用户是否点击了页面,用于判断是否播放声音
        loginShow: false,
        activeShow: false, //活动大厅弹窗
        getByUrl:{},//备案号，渠道码及app下载链接
        curJump: {users: []},//极限跳伞数据
        battleAnimationEnd:1
    },
    getters: {
        audioGetters:(state)=>{
            console.log(state)
            let audioGetters = {
                audio:true,
                video:state.audioState.video,
            }
            if(state.userInfo.soundEffects === '1'){
                audioGetters.audio = false
            }
            if(!state.audioState.audio){
                audioGetters.audio = false
            }
            console.log("audioGetters",audioGetters)
            return audioGetters
        },
        isLogin:(state) => {
            return Object.keys(state.userInfo).length !== 0
        },
    },
    mutations: {
        setLoginShow(state, val) {
            state.loginShow = val
        },
        setUserInfo(state, val) {
            state.userInfo = val
        },
        /* setLanguage(state, val) {
             state.language = val
         },*/
        setCDK(state, val) {
            state.cdkShow = val
        },
        setActiveShow(state, val) {
            state.activeShow = val
        },
        setAudio(state, type) {
            switch (type) { // 1视频，2音乐
                case 1:
                    state.audioState.video = !state.audioState.video
                    if (!state.audioState.video) {
                        state.audioState.audio = false
                    }
                    break;
                case 2:
                    if(state.userInfo.soundEffects === '1'){
                        createDialog.error(i18n.global.t('confirm.glblMusic'))
                        return;
                    }
                    if (!state.audioState.video) return
                    state.audioState.audio = !state.audioState.audio
                    break;
            }
            localStorage.setItem('audioState', JSON.stringify(state.audioState))
        },
        joinBattle(state, val) {
            state.joinBattle = val
        },
        creatBattle(state, val) {
            state.creatBattle = val
        },
        countChangeQueue(state, val) {
            state.countChangeQueue = val
        },
        rollEnd(state, val) {
            state.rollEnd = val
        },
        userClick(state, val) {
            state.userClick = val
        },
        setByUrl(state, val) {
            state.getByUrl = val
        },
        setCurJump(state, val){
            state.curJump = val
        },
        setAnimationEnd(state,val){
            state.battleAnimationEnd = val
        }
    },
    actions: {
        async actionUserInfo({commit}) {
            if (!localStorage.getItem('token')) return
            try {
                let data = await getUserInfo()
                data.spreadUrl =  window.location.origin + '?invitationCode=' + data.spreadCode
                if (data) commit("setUserInfo", data)
                if(data && data.money ){
                    data.money = new Decimal(data.money).toFixed(2)
                    // if (data) commit("setUserInfo", data)
                    console.log('用户信息', data)
                }


            } catch (e) {
                console.log(e)
            }
        },
        actionJoinBattle({commit}, val) {
            commit('joinBattle', val)
        },
        actionCreatBattle({commit}, val) {
            commit('creatBattle', val)
        },
        actionCountChangeQueue({commit}, val) {
            commit('countChangeQueue', val)
        },
        actionRollEnd({commit}, val) {
            commit('rollEnd', val)
        },

    },
    modules: {}
})
