<!--
时间:2023/8/15/015
用法:网站顶部的滚动轮播图
-->

<template>
  <div class='navSwiper' @mouseleave="mouseLeave">
    <div class="left">
      <div class="allList"
           @click="changeSwiper(1)"
           :class="{'allActive':swiperSelect === 1}">
      </div>
      <div class="OList"
           @click="changeSwiper(2)"
           :class="{'OActive':swiperSelect === 2 }">
      </div>
    </div>

  </div>
</template>

<script>
import {Autoplay} from "swiper/modules";
import {getNewSkin} from "@/axios/homes";
import {getRandomInt} from "@/utils/math";


let swiperId = 1
let timerTimeOut

export default {
  name: "NavSwiper",
  computed: {},
  data() {
    return {
      swiperList: [],
      swiper: true,
      modules: [Autoplay],
      swiperSelect: 2,// 1:全部,2:欧皇
      level: 3,
      pageSize: 50,
      dataList: []
    }
  },
  methods: {
    processingData() {
      this.clearTimer()
      timerTimeOut = setTimeout(() => {
        if (this.dataList.length) {
          let random = getRandomInt(1, 2)
          random = random > this.dataList.length ? this.dataList.length : random
          this.swiperList.unshift(...this.dataList.splice(0, random))
          if (this.swiperList.length > 20) {
            this.swiperList.splice(this.swiperList.length - random, this.swiperList.length)
          }
          this.processingData()
          // console.log('轮播列表', this.swiperList)
        } else {
          setTimeout(() => {
            this.getNewSkin()
          }, 5000)
        }
      },  this.swiperList.length === 0 ? 0 :getRandomInt(2000, 5000))
    },
    mouseOver() {
      this.clearTimer()
    },
    mouseLeave() {
      this.processingData()
    },
    changeSwiper(val) {
      if (this.swiperSelect === val) return
      this.level = val === 1 ? 5 : 3
      this.swiperSelect = val
      this.swiperList = []
      this.getNewSkin()
    },
    async getNewSkin() {
      this.clearTimer()
      const params = {
        level: this.level,
        pageSize: this.pageSize
      }
      try {
        let data = await getNewSkin(params)
        if (data.length === 0) return
        this.dataList = data.map(item => {
          item.skinBg = require(`@/assets/icon/skinbg${item.level}.png`)
          item.userBg = require('@/assets/icon/swiperUserbg.png')
          item.swiperId = swiperId
          swiperId++
          return item
        })

        if (data.length) {
          this.processingData()
          return
        }
        if (!this.swiperList.length) {
          this.swiperList = this.dataList
          await this.getNewSkin()
          return
        }
        this.processingData()

        // console.log('中奖列表', this.swiperList)
      } catch (e) {
        this.clearTimer()
      }
    },
    swiperClick(item) {
      console.log('点击了', item)
    },
    clearTimer() {
      clearTimeout(timerTimeOut)
      timerTimeOut = null
    },
    visibilitychange() {
      if (document.hidden) {
        this.clearTimer()
      } else {
        this.processingData()
      }
    }
  },
  created() {
    // this.getNewSkin()
    document.addEventListener('visibilitychange', this.visibilitychange);
  },
  beforeUnmount() {
    this.clearTimer()
    document.removeEventListener("visibilitychange", this.visibilitychange)
  }
}
</script>

<style scoped lang="scss">
.navSwiper {
  height: 5.4rem;
  width: 100%;
  overflow: hidden;
  margin-bottom: 0.17rem;
  display: flex;
  padding-left: 0.13rem;
  box-sizing: border-box;
  text-transform: none;

  .left {
    .allList {
      background: url("../assets/icon/tab1-a@2x.png") no-repeat center/cover;
    }

    .allActive {
      background: url("../assets/icon/tab1-b@2x.png") no-repeat center/cover;
    }

    .OList {
      background: url("../assets/icon/tab2-a@2x.png") no-repeat center/cover;
      bottom: 0.34rem;
    }

    .OActive {
      background: url("../assets/icon/tab2-b@2x.png") no-repeat center/cover;
    }

    .allList,
    .OList {
      width: 2.02rem;
      height: 3.1rem;
      position: relative;
      cursor: pointer;
    }
  }

  .right {
    display: flex;
    height: 100%;
    flex-grow: 1;
    //width: calc(100vw - );
    .item {
      width: 8.35rem;
      height: 5.4rem;
      margin: 0 0.1rem;
    }

    .card {
      width: 8.35rem;
      height: 5.4rem;
      background-repeat: no-repeat;
      background-position: center;
      background-size: 100% 100%; /**/
      box-sizing: border-box;


      .gun {
        width: 5.5rem;
        height: 2.79rem;
        @include p-ab-center;
        top: 40%;
      }

      .text {
        position: absolute;
        bottom: 0.46rem;
        left: 0.63rem;
        width: 100%;

        .skinEllipsis {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          -webkit-text-overflow: ellipsis;
          font-size: 0.583rem;
          width: 90%;
          text-align: left;
        }

        .skinRoll {
          display: none;

          span {
            margin: 0 2rem;
          }
        }


        .boxName {
          font-size: 0.5rem;
          color: #A7A7A7;
        }

        .skinName,
        .boxName {
          text-align: left;
        }

        @media screen and (max-width: $windowSize-md) {
          .skinEllipsis {
            font-size: 0.85rem;
          }
          .boxName {
            font-size: 0.64rem;
          }
        }
      }


      .avatarsImg {
        height: 2.03rem;
        width: 2.03rem;
        border-radius: 50%;
        background: url("../assets/icon/avatarCircle.png") no-repeat center/cover;
        padding: 0.3rem;
      }


      .userName {
        margin-top: 0.4rem;
        font-size: 14px;
        color: $theme-color2;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -webkit-text-overflow: ellipsis;
        width: 100%;
      }
    }


    .cardTop {
      padding: 0 0.63rem;
    }

    .carBottom {
      padding-top: 0.83rem;
    }
  }
}


.fade-enter-active {
  animation: scrollFirstChild 1s;
}

@keyframes scrollFirstChild {
  0% {
    width: 0;
    opacity: 0
  }

  100% {
    width: 8.35rem;
    opacity: 1
  }
}
</style>
