<!--
时间:2023/8/15/015
用法:顶部导航栏
-->

<template>
  <div class='headerNav' id="headerNav">
    <div class="left">

    </div>
    <div class="right">


    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import {language} from "@/common/config";
import {mapState} from "vuex";
import MenuList from "@/layout/MenuList.vue";
import {goPath} from "@/router/goPath";
import {updateUserInfo} from "@/axios/information";

export default {
  name: "MenuList",
  provide() {
    return {
      cdkShowFun: this.cdkShowFun,
      showDownload: this.showDownload,
      openQQ: this.openQQ,
    };
  },
  computed: {
    ...mapState(['userInfo', 'getByUrl']),
  },
  components: { MenuList,},
  data() {
    return {

    }
  },
  methods: {
    ...mapMutations(['setLoginShow', 'setCDK', 'setLoginShow', 'setActiveShow']),
  },
  mounted() {
    window.addEventListener('click', this.closeMenu)
  },
  beforeUnmount() {
    window.removeEventListener('click', this.closeMenu)
  }
}
</script>

<style scoped lang="scss">
.headerNav {
  height: 3.77rem;
  margin-bottom: 0.17rem;
  padding-left: 0.79rem;
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  background-color: #171C3C;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100vw;
  box-sizing: border-box;

}

@media screen and (max-width: $windowSize-md) {
  .headerNav {
    padding: 0 0.8rem;

  }
}
</style>