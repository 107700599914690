<!--
时间:2023/9/25/025
用法:消息提示弹窗
-->

<template>
  <transition name="message">
    <div class="message-bg">
      <div class='Message  animate__animated'
           :class="{'animate__bounceInDown':!isShow,
          'animate__bounceOutUp':isShow}"
           id="MessageDialog"
           :style="{'background-image':`url(${typeList.bgImage || ''})`,'--color':typeList.bgColor}">
        <div class="messageTitle">
          <img class="icon" :src="typeList.icon" alt="">
          <span>{{ typeList.title }}</span>
        </div>

        <p class="text">{{ typeList.text.msg || typeList.text }}</p>

        <div v-if="isConfirm">
          <div class="btn" @click="cancelCk">取消</div>
          <div class="btn" @click="confirmCk">确认</div>
        </div>
      </div>
    </div>
  </transition>

</template>

<script>

import {audioList, audioPlay} from "@/common/audio";

export default {
  name: "Message",
  computed: {
    typeList() {
      let list = this.list[this.type]
      let msg = {
        title: this.title || list.defaultTitle,
        text: this.text
      }
      return {...list, ...msg}
    },
  },
  props: {
    type: {
      type: String,
      default: 'success'
    },
    title: {
      type: String,
      default: ''
    },
    isConfirm: {
      type: Boolean,
      default: false
    },
    text: {
      type: [Object, String],
      default: ''
    },
    fun: {
      type: Function,
      default: () => {
        console.log(1)
      }
    },
    ok: {
      type: Function,
      default: () => {
        console.log(1)
      }
    },
    cancel: {
      type: Function,
      default: () => {
        console.log(1)
      }
    },
    close: {
      type: Function,
      default: () => {
      }
    },
    duration:{
      type:Number,
      default:5000
    }
    /* show:{
       type:Function,
       default:()=>{}
     }*/
  },
  data() {
    return {
      isShow: false,
      list: {
        'success': {
          icon: require('@/assets/popup/success.png'),
          bgColor: "#1ECE77",
          // bgImage: require('@/assets/popup/green.png'),
          defaultTitle: 'Success' ,
        },
        'warn': {
          icon: require('@/assets/popup/warning.png'),
          bgColor: "#6917FF",
          // bgImage: require('@/assets/popup/warningBg.png'),
          defaultTitle: 'Warning',
        },
        'error': {
          icon: require('@/assets/popup/error.png'),
          bgColor: "#EE2020",
          // bgImage: require('@/assets/popup/redbg.png'),
          defaultTitle: 'Error',
        },
      },
    }
  },
  created() {
    let duration = this.duration
    switch (this.type) {
      case 'error':
        audioPlay(audioList.error).play()
        duration = 2000
        break;
    }

    if (!this.isConfirm) {
      let timer = setTimeout(() => {
        this.closePopup()
        clearTimeout(timer)
      }, duration)
    }
  },
  methods: {
    cancelCk() {
      this.cancel();
      this.closePopup();
    },
    confirmCk() {
      this.ok();
      this.closePopup();
    },
    closePopup() {
      this.isShow = true
      setTimeout(() => {
        this.close()
      }, 500)
    }
  },
  beforeUnmount() {
    this.isShow = true
  }
}
</script>

<style scoped lang="scss">
.message-bg {
  width: 100%;
  @include center;

  .Message {
    width: 13.38rem;
    position: fixed;
    top: 20%;
    //transform: translateX(-50%);
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
    padding: 0.8rem 1rem;
    box-sizing: border-box;
    border-radius: 0.17rem;
    overflow: hidden;
    background-color: #101116;
    animation-duration: 0.5s;
    z-index: 2023;

    &::after {
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      display: block;
      height: 0.13rem;
      background-color: var(--color);
    }
  }

  .messageTitle {
    display: flex;
    justify-content: flex-start;
    font-size: 0.67rem;
    margin-bottom: 0.54rem;
    font-weight: bold;
  }

  .icon {
    width: 1.03rem;
    height: 1.03rem;
    margin-right: 0.33rem;
  }

  .text {
    font-size: 0.5rem;
  }
}

@media screen and (max-width: $windowSize-md) {
  .message-bg {
    .Message {
      width: 24.24rem;
      padding: 1.9rem 1.5rem;

      &::after {
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        content: '';
        display: block;
        height: 0.23rem;
        background-color: var(--color);
      }

      .messageTitle {
        font-size: 1.08rem;
      }

      .icon {
        width: 1.6rem;
        height: 1.6rem;
      }
    }
    .icon {
      width: 1.03rem;
      height: 1.03rem;
      margin-right: 0.33rem;
    }

    .text {
      font-size: 1rem;
    }
  }
}
</style>
