<!--
时间:2023/8/15/015
用法:顶部导航栏
-->

<template xmlns="http://www.w3.org/1999/html">
  <div class="headerNavTop">

    <div class='headerNav _windows' id="headerNav">
      <div class="left">
        <img @click="$router.push('/')" src="../assets/icon/vip@2x.png" alt="">
      </div>
      <div class="right">
        <div v-show="searchBoxSelectHide" class="searchBoxSelectHide">
          <div @click="setText(index)"
               :class="`searchBoxSelectHideOption ${'缘神启动'+index===showMsg?'searchBoxSelectHideOptionSelected':''}`"
               v-for="index in 10" :key="`asdf`+index">
            萨嘎是的{{ index }}
          </div>
        </div>
        <div class="searchBox">
          <div @click="searchBoxSelectHide=!searchBoxSelectHide" class="searchBoxSelect">
            {{ showMsg }}
          </div>
          <div class="searchBoxInput">
            <input v-model="msgKey" type="text">
          </div>
          <div @click="queryData" class="searchBoxBtn">
            搜索
          </div>
        </div>
      </div>
    </div>

    <div class="homePageTabTop _windows">
      <div class="homePageTab">
        <div class="homePageTabCard" @click="$router.push('/')" v-for="(index) in 3 ">
          我要买号{{ index }}
        </div>
      </div>
    </div>

    <div class="headerQuery _mobile " >
      <div class="homePage" @click="$router.push('/')"  >首</div>
      <input v-model="msgKey" type="text" @keydown.enter="handleEnterKey" />
      <div @click="queryData" >搜索</div>
    </div>

  </div>
</template>

<script>
import {mapMutations} from "vuex";
import {mapState} from "vuex";
import MenuList from "@/layout/MenuList.vue";

export default {
  name: "HeaderNav",
  provide() {
    return {}
  },
  computed: {
    ...mapState(['userInfo', 'getByUrl']),
  },
  components: {MenuList},
  data() {
    return {
      searchBoxSelectHide: false,
      msgKey: '',
      showMsg: '选择游戏'
    }
  },
  methods: {
    ...mapMutations(['setLoginShow', 'setCDK', 'setLoginShow', 'setActiveShow']),
    setText(index) {
      this.showMsg = "缘神启动" + index;
      this.searchBoxSelectHide = false;
    },
    queryData() {
      console.log('queryData' + this.showMsg + this.msgKey)
    },
    handleEnterKey(event) {
      if (event.key === 'Enter') {
        // 在这里添加你想要执行的逻辑
        this.queryData();
      }
    }
  },
  mounted() {
    window.addEventListener('click', this.closeMenu)
  },
  beforeUnmount() {
    window.removeEventListener('click', this.closeMenu)
  }
}
</script>

<style scoped lang="scss">
.headerNavTop {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;


  .homePageTabTop {

    width: 100%;
    display: flex;
    justify-content: center;

    .homePageTab {
      display: flex;
      width: var(--window-width);

      .homePageTabCard {
        background-color: #66afe9;
        color: #EEEEEE;
        border: 1px solid #66afe9;
        width: 6rem;
        height: 2rem;
        border-radius: 0.5rem 0.5rem 0 0;
        @include center;
        font-size: larger;
        font-weight: bold;
        margin-right: 1rem;
        cursor: pointer;
        user-select: none;

      }
    }
  }

  .headerNav {
    height: var(--window-headerNav-height);
    width: var(--window-width);
    margin-bottom: 0.17rem;
    padding-left: 0.79rem;
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    z-index: 100;
    box-sizing: border-box;

    .left {
      @include center;
    }

    .right {
      @include center;

      .searchBoxSelectHide {
        background-color: #FFFFFF;
        border: 1px solid #ccc;
        border-radius: 5px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        color: #000;
        cursor: pointer;
        user-select: none;
        overflow-y: auto;
        width: 6.5rem;
        height: 10rem;
        display: block;
        z-index: 9;
        transform: translate(6.6rem, 6.2rem);

        .searchBoxSelectHideOption {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 1.5rem;
          //border-bottom: 0.1px solid #ccc;
          border-top: 0.1px solid #ccc;

        }

        .searchBoxSelectHideOptionSelected {
          color: red;
        }

        .searchBoxSelectHideOption:hover {
          background-color: #F3F3F5;
        }
      }

      .searchBox {
        display: flex;
        position: relative;
        align-items: center;
        width: 24rem;
        height: 2.5rem;
        border: 0.1rem solid #66afe9;
        border-radius: 0.3rem;
        text-align: center;

        .searchBoxSelect {
          color: green;
          width: 7rem;
          height: 100%;
          border-right: 0.1rem solid #66afe9;
          @include center;
          cursor: pointer;
          user-select: none;
        }


        .searchBoxInput {
          width: 16rem;
          height: 100%;

          > input {
            width: 100%;
            height: 100%;
            border: none;
            outline: none;
            font-size: 0.8rem;
            caret-color: red; /* 将光标颜色设置为红色 */
            margin-left: 0.3rem;
          }
        }

        .searchBoxBtn {
          width: 4rem;
          height: 100%;
          color: #EEE;
          background-color: #66afe9;
          border-left: 0.1rem solid #66afe9;
          font-size: 0.7rem;
          @include center;
          cursor: pointer;
          user-select: none;


        }


      }

    }
  }

}

@media screen and (max-width: $windowSize-md) {
  .headerNavTop {

    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    background-color: #f78c8c;
    height: 3rem;

    .headerQuery {
      width: 80%;
      @include center;

      .homePage{

        border: 1px solid #f5f5f5;
        background-color: #f5f5f5;
        border-radius: 0;
        margin-right: 0.5rem;
        color: #171C3C;

      }
      > input {
        height: 2rem;
        width: calc(100% - 4.5rem);
        border: 1px solid #f5f5f5;
        background-color: #f5f5f5;
        border-radius: 0.3rem 0 0 0.3rem
      }

      > div {
        width: 4rem;
        height: 2rem;
        border: 1px solid #66afe9;
        background-color: #66afe9;
        border-radius: 0 0.3rem 0.3rem 0;
        @include center;
      }

    }
  }
}
</style>