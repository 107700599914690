import rule from "@/i18n/rule";

export default {
    nav: {
        // 导航栏
        welfare: "福利",
        mySkins: '我的物品',
        myWelfare: '我的福利',
        myReward: '我的佣金',
        information: "个人中心",
        historicalBilling: '历史账单',
        login: "登录",
        logout: "退出登录",
        blindBox: '盲盒',
        rollRoom: "Roll房",
        dream: "追梦",
        battle: "竞技场",
        bomb: "拆弹",
        refill: "充值",
        market:'交易市场',
        // 登陆弹窗
        loginTitle: '我们需要确保您可以使用csbox,请确认：',
        my18: '我已满18岁',
        agree: "我已阅读并同意",
        usrAgreement: '用户协议',
        privacyAgreement: '隐私协议',
        antiMoneyLaundering: '反洗钱协议',
        // 电脑版footer内容
        openCases: '开箱总数',
        users: '玩家总数',
        online: '在线玩家',
        footerText1: "盲盒玩法多种多样，饰品提取安全便捷！",
        // 手机版侧边栏
        games: "游戏",
        preferential: "特惠",
        free: "免费",
        recharge: "充值",
        cdk: "红包",
        other: "其他",
        QQ: "QQ群",
        custServ: "客服",
        App: "App",
        glblMusicEnabled: "已启用音效",
        soundEffectsDisabled: "已禁用音效",
        questions:"常见问题",
        aboutUs:"关于我们",
        pleaseEntrANickname:"请输入昵称",
        pleaseEntrYourEmailAddr:"请输入手机号",
        pleaseEntrTheVerificationCd:"请输入验证码",
        obtainVerificationCd:"获取验证码",
        pleaseOutputTheInptPsw:"请输入密码",
        pleaseConfThePsw:"请确认密码",
        pleaseEntrTheInvtCd:"请输入邀请码（选填）",
        noSteam:"没有steam？",
        emailLogin:"手机号登陆",
        passwordLogin:"密码登陆",
        verificationCdLogin:"验证码登陆",
        register:"注册",
        steamLogin:"steam登录",
        forgetPsw:"忘记密码？",
        conf:"确认",
        retrievePsw:"找回密码",
        ruleDream:"追梦规则",
        exchange:"兑换",
        inptCdk:"请输入CDK口令",
        redemptionSuccessful: "兑换成功，金币",
        ruleBattle:"竞技场规则",
        constellation:"星座",
    },
    blindBox: {
        //开箱页面
        open: "打开",
        buy: "购买",
        bestDrp: "最近掉落",
        boxContains: "箱子包含",
        buyTips1: "您没有足够的资金来开箱!",
        mbrDrp:"会员掉落",
        anchorDrp:"主播掉落",
    },
    roll: {
        ofcl: "官方",
        anchor: "主播",
        iParticipatedIn: "我参与的",
        haveInHand: "进行中",
        itsOvr: "已结束",
        participated: "已参与",
        viewingRooms: "查看房间",
        joinNow: "立即加入",
        deadline: "截止时间",
        totVal: "总价值",
        items: "物品",
        players: "玩家",
        //roll详情
        countdownToLottery: "开奖倒计时",
        prtpConditions: "参与条件",
        includingPrizes: "包含奖品",
        participatingUsers: "参与用户",
        awrdLst: "获奖名单",
        joinRm: "加入房间"
    },
    dream: {
        chooseAccessories: "选择饰品",
        probability: "概率",
        upg: "追梦",
        upgTips: "*追梦概率范围为{num1}%~{num2}%",
        //追梦选择饰品弹窗
        inputPlaceholder1: "输入装备关键字",
        minPrc:'最低价',
        maxPrc:'最高价',
    },
    battle: {
        //对战列表
        allBattles: "全部对战",
        rankingLst: "排行榜",
        historicalRecords: "历史记录",
        myRecords: "我的记录",
        creABattle: "创建对战",
        //创建对战
        addBox: "添加箱子",
        robot: "机器人",
        combatCost: "战斗成本",
        createBattle: "创建对战",
        chooseAnItem: "选择物品",
        incorrectNumberOfPeople: "人数不正确",
        inputPlaceholder1: "输入箱子关键字",
        thisMorning: "今天上午",
        thisAfternoon: "今天下午",
        selected: "已选:{num}个",
        coVal: "共值",
        //对战详情
        ready: "已准备",
        drawnJewelry: "抽中饰品",
        obtainJewelry: "获得饰品",
        //排行榜
        today: "今天",
        yesterday: "昨天",
        //对战结束弹窗
        battleIsOvr: "对战结束",
        winner: "胜利者",
        oneMoreRound: "再来一局",
        confJoin:"是否确认加入？",
    },
    information: {
        //个人中心首页
        invtCd: "邀请码",
        bal: '余额',
        rchg: "充值",
        orderNumber: '订单号',
        transactionLnk: "STEAM交易链接",
        steamLinkTips1: "1、交易链接一旦绑定则无法更换,请谨慎操作,同一链接更新token不限制.",
        steamLinkTips2: "2、请勿绑定他人的steam交易链接",
        steamInputPlaceholder1: "输入您的steam交易链接",
        obtain: "获取",
        saveAddr: "保存地址",
        save: "保存",
        mySkins: '我的物品',
        myWelfare: '我的福利',
        rchgDetails: "充值明细",
        incomeDetails: "收支明细",
        myReward: '我的佣金',
        // 我的物品页面
        retrieving: "正在取回",
        retrieveRecords: "取回记录",
        numOrnaments: "共{num}件饰品",
        obtainingTm: "获得时间",
        val: "价值",
        selectedNum: "已选 {num} 件",
        retrieve: "取回",
        chooseABox: "去开箱",
        noItems: "你还没有物品",
        noRecIsCur: "没有当前记录",
        //佣金页面
        generalizationCd: "推广码",
        cpy: "复制",
        promLnk: "推广链接",
        partnerRules: "合伙人规则",
        totNumOfInvitations: "邀请总人数",
        effectiveUsers: "有效用户",
        accumRewards: "累计奖励",
        rewardRatio: "返佣比例",
        lvlDsc: "等级说明",
        invtRec: "邀请记录",
        date: "日期",
        invitee: "被邀请人",
        usrRchg: "用户购币",
        myRewards: "返佣",
        rewardLvl:"邀请等级",
        invitedUsrRchg:"被邀请用户购币",
        promAndAcq:"推广用户比例",
        incmStmt:"收益说明",
        changeAvatar:"点击更换头像",
        edt:"编辑",
    },
    active:{
        signIn:"登陆签到",
        rechargeSign:"充值签到",
        signedInDays:`已签到{html1}{num}{html2}天`,
        sign:"签到",
        signedIn:"已签到",
        claim:"领取",
        claimed:"已领取",
        tips:"连续7天{type}，第7天最高可获取{num}红包福利（中断任何一天将重置回第一天）",
        signSuccess:"签到成功",
        claimSuccess:"领取成功",
        active20:"我的福利",
        active21:"VIP等级奖励",
        active22:"累计金额",
        active23:"购买金额",
        active24:"加送奖励",
        active25:"VIP等级说明",
        active26:"会员如何升级？",
        active27:"会员达到VIPn后，30天内购买金币VIP（n+1）与VIPn的门槛差值即可升级VIP等级；购1A币=1成长值。",
    },
    confirm: {
        success: "成功",
        warning: "警告",
        error: "错误",
        noDataAvailable: "暂无数据",
        //老虎机抽奖结果弹窗
        decompose: "分解",
        decomposeAll: "全部分解",
        cont: "继续",
        //confirm提示弹窗
        confDecomposition: "确认分解{num}件装备吗？",
        common: "共",
        cancel: "取消",
        confirm: "确认",
        confDec: "确认分解？",
        confOpn: "是否确认打开？",
        conf: "是否确认？" ,
        //msg提示弹窗
        decomposeSuccess: "分解成功!",
        theMinimumProbabilityIs: "概率最小为{num}",
        notLoggedIn: "未登录",
        noJewelrySelected: "未选择饰品",
        agreeToATreaty: "请阅读并同意以下条约",
        copySuccess: "复制成功",
        copyError: "复制失败",
        pleaseFollow: "取回中，请稍后",
        notYetOpn: "暂未开放",
        glblMusic: "请先开启全局音乐",
        amtCannotBeZero: "金额不能为0",
        pswModSuccessful:"密码修改成功",
        loginSuccessful:"登录成功",
        loginWasSuccessful:"注册成功，请登陆！",
        emailFmtErr:"手机号格式错误",
        vSntSuccess:"验证码发送成功",
        pswInconsistency:"密码不一致",
        entrTheVerificationCd:"请输入验证码",
        theMinPswLenIs6:"密码最小长度为6",
        pleaseEntrANickname:"请输入昵称",
    },
    //常用的
    common: {
        //单位
        day: '天',
        hh: "时",
        mm: '分',
        ss: "秒",
        piece: "件",
        // title常用
        back: "返回",
        gameRules: "游戏规则",
        //表格常用
        players: "玩家",
        price: "价格",
        reset: "重置",
        round:"回合",
        cases: "箱子",
        value: "价值",
        actions: "操作",
        watch: "观战",
        join: "加入",
        ranking: "排行",
        bestDrp: "最佳掉落",
        date: "日期",
        st: "状态",
        purp: "用途",
        amtOfMoney: "金额",
        allElection: "全选",
        typ:"类型",
        numericalVal:"数值",

    },
    incomeType: {
        //个人中心收支type
        whole: "全部",
        incm: "收入",
        expenditure: "支出",
        purCurr: "购币",
        recovery: "回收",
        unpacking: "开箱",
        commission: "佣金",
        redEnvelopes: "红包",
        war: "对战",
        shoppingMallPur: "商城购买",
        chasingDreams: "追梦",
        coinPurGft: "购币赠送",
        cshWdrl: "提现",
        rlRm: "ROLL房",
        regGifts: "注册赠送",
        addRewards: "加送奖励",
        redEnvelopeReward: "红包奖励",
        luckyTurntable: "幸运转盘",
        bkOffcDistribution: "后台发放",
        skinSynthesis: "皮肤合成",
        sgnInReward: "签到奖励",
        newDreamChasing: "新追梦",
        unpackingKing: "拆箱王",
        skydiverHd: "极限跳伞",
        inviteRebate: "邀请返利",
        taskRewards: "任务奖励",
        benefits: "周一福利大放送",
        deliver: "笔笔送",
        turntable: "大转盘",
        turntablegive: "转盘充值赠送",
        breakgive: "分解赠送",
        comeback: "雪中送炭",
        jump: "极限跳伞",
        crdDecomposition:"卡片分解",
        transferAndDelivery:"转盘加送",
        oldCustomerRepurchase:"老客复购",
    },
    provably:{
        provably:"公平性验证",
        provablyDsc:"公平性验证说明",
        text1:"1、玩家每次开箱会产生一个roll点，并获得roll点所对应的皮肤。",
        text2:"2、盲盒、竞技场 等玩法各自一套玩家种子和公开哈希。例如: 盲盒a箱子和b箱子在roll点上没有区别，只是获得皮肤有区别。",
        text3:"3、参与运算的加密值如下:",
        text4:"4、我们提供该roll点算法的开源代码和测试平台，懂程序员的朋友可以单机运算，在网站服务器无关联的情况下，看是否结果一致。",
        clientSeed:"玩家种子",
        publicHash:"公开哈希",
        round:"回合数",
        secretHash:"秘密哈希",
        secretSalt:"秘盐",
        createTime:"创建时间",
        enterClientSeed:"请输入玩家种子",
        enterPublicHash:"请输入公开哈希",
        enterSecretHash:"请输入秘密哈希",
        enterSecretSalt:"请输入secret salt",
        enterRound:"请输入回合数",
        p1:"也就是说，有两个值是用户可以自己更改的，且这两个值参与了运算，可以改变运算结果roll点。例如:个人种子默认1.手动更改成2改了之后roll点结果为12345，其他参数不变的情况下，再去用工具验证1的结果，会发现种子为1的情况下结果不为12345",
        p2:"实例中，在重置公开哈希之后，可看到之前游戏的秘密哈希和秘盐，然后可以去验算之前的结果是否正确。也可以拿之前的值去测，较大回合下(例如10w次)概率分布是否合理。",
        opnSrcAddr:"开源地址",
        download:"下载离线验证器",
        curSeed:"当前种子",
        preSeed:"历史种子",
        chg:"更改",
        reset:"重置",
        oneClkCpy:"一键复制",
        validator:"公平性验证器",
        attachment1:"(玩家可更改)",
        attachment2:"(玩家可重置)",
        attachment3:"(随游戏次数增加)",
        resetDisp:"(重置公开哈希后展示)",
        legitimateSeed:"请输入合法种子",
        entrANewSeed:"输入新的种子",
        seedReg:"三个字符（含）以上的英文字母、数字或中文",
        oneClkPaste:"一键粘贴",
        rlPnt:"roll点",
        waitVerification:"等待验证"
    },
    activity:{
        welfare:"活动规则",
        welfare1:"1.每个周一0点开始至周日23点59分59秒时间段内充值的会员，在下周一可以在此活动页面领取上一周累计充值金额的5%;",
        welfare2:"2.累计充值金额要满足100元以上;",
        welfare3:"3.领取赠送福利有效期是7天，没有领取则自动失效;",
        welfare4:"4.本活动最终解释权归本平台所有。",
        tableHeader1:"充值时间",
        tableHeader2:"充值金额",
        tableHeader3:"赠送比例",
        tableHeader4:"领取金额",
        tableHeader5:"状态",
        deliverSpan:"玩家领取后，将自动到账玩家钱包，可进入钱包查看",
        deliverPens:"笔数",
    },
    privacyClause:{
        usrAgreement:rule.usrAgreement.zh,
        privacyAgreement:rule.privacyAgreement.zh,
        aboutUs:rule.aboutUs.zh,
        questions:rule.questions.zh,
        ruleDream:rule.ruleDream.zh,
        ruleBattle:rule.ruleBattle.zh
    },
    217: '累计存款满{num}元后，可享受每日一次免费抽奖 累计存款越多抽到的奖品越好哦',
    218: '下次充值享{num}%赠送',
    219: '今日有效',
    // 十二星座
    238:"白羊座",
    239:"金牛座",
    240:"双子座",
    241:"巨蟹座",
    242:"狮子座",
    243:"处女座",
    244:"天秤座",
    245:"天蝎座",
    246:"射手座",
    247:"摩羯座",
    248:"水瓶座",
    249:"双鱼座",

    250:"十二星座",
    251:"星座背包",
    252:"开始",
    253:"成套回收价",
    254:"成套回收",
    255:"请输入分解数量",
    256:"不足一套,无法分解!",
    257:"开心收下",
}
