import {createRouter, createWebHashHistory, createWebHistory} from 'vue-router'
import Layout from '../layout/layout.vue'
import store from '../store'

const routes = [
    {
        path: '/',
        name: '',
        component: Layout,
        meta: {
            title: "Home"
        },
        children: [
            {
                path: '',
                name: '',
                component: () => import('../views/homePage')
            },
            {
                path: '/homes',
                name: 'homes',
                component: () => import('../views/homePage')
            },
            {
                path: '/details/:id',
                name: 'details',
                component: () => import('../views/details')
            },
            ]
    },
    {//手机版个人中心
        path: "/profile",
        name: 'profile',
        component: () => import('../layout/profileLayout.vue'),
        meta: {
            login: true
        },
        children: [
            {
                path: '',//佣金页面
                name: '',
                component: () => import('../views/homePage')
            },
            {
                path: '/homes',//订单页面
                name: 'homes',
                component: () => import('../views/homePage')
            },
        ]
    },
]

const router = createRouter({
    history: process.env.NODE_ENV === 'app' ? createWebHashHistory("/#") : createWebHistory(),
    routes,
    scrollBehavior: () => {
        window.scrollTo(0, 0);
    }
})

router.beforeResolve(async (to, from, next) => {

    const isLoginRequired = to.meta.login;
    const isLoggedIn = !!localStorage.getItem('token'); // 使用异步操作获取登录状态
    if (isLoginRequired && !isLoggedIn) {
        store.commit('setLoginShow', true);
        localStorage.setItem('fromPath', to.fullPath);
        next(from.fullPath); // 阻止路由跳转
    } else {
        if (to.fullPath.indexOf('/steamBack') < 0) {
            localStorage.removeItem('fromPath');
        }
        next();
    }
});
export default router
