import router from '@/router'
import i18n from "@/i18n";



export default {
    methods: {
        /**
         * 获取随机ID
         * @returns {string}
         */
        $getUid() {
            function S4() {
                return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1)
            }

            return 'tag' + S4() + S4() + S4() + S4()
        },
        /**
         * 获取登陆用户信息
         * @returns {string}
         */
        $getLoginUser() {
            const user = JSON.parse(sessionStorage.getItem('userData'))
            if (!user) {
                // 跳转路由
                router.push({
                    name: 'login'
                })
            }
            return user
        },
        /**
         * 时间戳 转 时间
         * @param value
         * @returns {string}
         */
        $formatDateTime(value) {
            if (!value) return ''
            try {
                const date = new Date(value)
                if (!date) return ''
                const y = date.getFullYear()
                let MM = date.getMonth() + 1
                MM = MM < 10 ? ('0' + MM) : MM
                let d = date.getDate()
                d = d < 10 ? ('0' + d) : d
                let h = date.getHours()
                h = h < 10 ? ('0' + h) : h
                let m = date.getMinutes()
                m = m < 10 ? ('0' + m) : m
                let s = date.getSeconds()
                s = s < 10 ? ('0' + s) : s
                return y + '-' + MM + '-' + d + ' ' + h + ':' + m + ':' + s
            } catch (e) {
                console.log(e)
                return ''
            }
        }, /**
         * 时间戳 转 时间 年月日
         * @param value
         * @returns {string}
         */
        $formatDateTimeStr(value) {
            if (!value) return ''
            try {
                const date = new Date(value)
                if (!date) return ''
                const y = date.getFullYear()
                let MM = date.getMonth() + 1
                MM = MM < 10 ? ('0' + MM) : MM
                let d = date.getDate()
                d = d < 10 ? ('0' + d) : d
                let h = date.getHours()
                h = h < 10 ? ('0' + h) : h
                let m = date.getMinutes()
                m = m < 10 ? ('0' + m) : m
                let s = date.getSeconds()
                s = s < 10 ? ('0' + s) : s
                return y + '年' + MM + '月' + d + '日 ' + h + ':' + m + ':' + s
            } catch (e) {
                console.log(e)
                return ''
            }
        },
        /**
         * 时间戳 转 时间
         * @param value
         * @returns {string}
         */
        $formatDate(value) {
            if (!value) return ''
            try {
                const date = new Date(value)
                if (!date) return ''
                const y = date.getFullYear()
                let MM = date.getMonth() + 1
                MM = MM < 10 ? ('0' + MM) : MM
                let d = date.getDate()
                d = d < 10 ? ('0' + d) : d
                return y + '-' + MM + '-' + d
            } catch (e) {
                console.log(e)
                return ''
            }
        },
        /**
         * 时间戳 转 时间
         * @param value
         * @returns {string}
         */
        $formatDate2(value) {
            if (!value) return ''
            try {
                // let val = value;
                // if (val.indexOf('-') >= 0) {
                //     val = val.replace(/-/g, 'o');
                // }
                const date = new Date(value)
                if (!date) return ''
                const y = date.getFullYear()
                let MM = date.getMonth() + 1
                MM = MM < 10 ? ('0' + MM) : MM
                let d = date.getDate()
                d = d < 10 ? ('0' + d) : d
                return y + '' + MM + '' + d
            } catch (e) {
                console.log(e)
                return ''
            }
        },
        /**
         * 时间戳 转 时间
         * @param value
         * @returns {string}
         */
        $formatDate3(value) {
            if (!value) return ''
            try {
                // let val = value;
                // if (val.indexOf('-') >= 0) {
                //     val = val.replace(/-/g, 'o');
                // }
                const date = new Date(value)
                if (!date) return ''
                const y = date.getFullYear()
                let MM = date.getMonth() + 1
                MM = MM < 10 ? ('0' + MM) : MM
                let d = date.getDate()
                d = d < 10 ? ('0' + d) : d
                return y + '/' + MM + '/' + d
            } catch (e) {
                console.log(e)
                return ''
            }
        },
        $getDate() {
            return this.$formatDate(new Date())
        },
        $getDateYYYYMMDD() {
            return this.$formatDate2(new Date())
        },
        $getDateTime() {
            return this.$formatDateTime(new Date())
        },
        /**
         * 取值 list<map<value,text>>
         * @param array list<map<value,text>>
         * @param key value
         * @returns {*} text
         */
        $getArrayDate(array, key) {
            try {
                for (let i = 0; i < array.length; i++) {
                    if (array[i].value == key || array[i].value == (key + '')) {
                        return array[i].text
                    }
                }
            } catch (e) {
                console.log(e.message)
            }
            return ''
        },
        $getColor(array, key) {
            try {
                for (let i = 0; i < array.length; i++) {
                    if (array[i].value == key || array[i].value == (key + '')) {
                        return array[i].color
                    }
                }
            } catch (e) {
                console.log(e.message)
            }
            return {}
        },
        /**
         * 取值 list<map<value,text>>
         * @param array list<map<value,text>>
         * @param key value
         * @param customize
         * @returns {*} text
         */
        $getArrayData(array, key, customize) {
            try {
                for (let i = 0; i < array.length; i++) {
                    if (array[i].id == key || array[i].value == key || array[i].value == (key + '')) {
                        return array[i].name || array[i].id
                    }
                }
            } catch (e) {
                console.log(e.message)
            }
            return customize
        },
        /**
         * array 数组转字符串用 , 分隔
         * @param array
         */
        $ArrayToStr(array) {
            let end = ''
            for (let i = 0; i < array.length; i++) {
                end += array[i]
                if (i + 1 !== array.length) {
                    end += ','
                }
            }
            return end
        },
        $isEmpty(val){
            if(val==null||val==undefined){
                return true
            }
            return false;
        },
        /**
         * 日期加减天数
         * @param date 日期 date类型
         * @param day 天数 可以为父
         * @returns {Date}
         */
        $dateAddDay(date, day) {
            const dataTime = date.getTime() + (1000 * 60 * 60 * 24 * day)
            const newDate = new Date(dataTime)
            return newDate
        },
        /**
         * 日期 转换 20190505
         * @param date
         * @returns {string}
         */
        $formatStrDate(date) {
            if (undefined === date || date === null || date.length < 6) return ''
            return date.substr(0, 4) + '-' + date.substr(4, 2) + '-' + date.substr(6, 2)
        },
        // 时间戳转日期带时间
        getTimeChange(val) {
            const getDate = new Date(val)
            return getDate.getFullYear() + '-' + (getDate.getMonth() + 1 > 9 ? getDate.getMonth() + 1 : '0' + (getDate.getMonth() + 1)) + '-' + (getDate.getDate() > 9 ? getDate.getDate() : '0' + getDate.getDate()) + ' ' + (getDate.getHours() > 9 ? getDate.getHours() : '0' + getDate.getHours()) + ':' + (getDate.getMinutes() > 9 ? getDate.getMinutes() : '0' + getDate.getMinutes()) + ':' + (getDate.getSeconds() > 9 ? getDate.getSeconds() : '0' + getDate.getSeconds())
        },
        // 时间戳转日期
        getTimeChangeDay(val) {
            const getDate = new Date(val)
            return getDate.getFullYear() + '-' + (getDate.getMonth() + 1 > 9 ? getDate.getMonth() + 1 : '0' + (getDate.getMonth() + 1)) + '-' + (getDate.getDate() > 9 ? getDate.getDate() : '0' + getDate.getDate())
        },
        /**
         * 日期 转换 20190505
         * @param date
         * @returns {string}
         */
        $formatStrDateStc(date, stc) {
            if (stc === undefined) stc = '-'
            if (undefined === date || date === null || date.length < 6) return ''
            return date.substr(0, 4) + stc + date.substr(4, 2) + stc + date.substr(6, 2)
        },
        /**
         * 日期 转换 20190505010211
         * @param date
         * @returns {string}
         */
        $formatStrDateTime(date) {
            if (undefined === date || date === null || date.length < 14) return ''
            let dataCar = date.substr(0, 4) + '-' + date.substr(4, 2) + '-' + date.substr(6, 2)
            dataCar += ' ' + date.substr(8, 2) + ':' + date.substr(10, 2) + ':' + date.substr(12, 2)
            return dataCar
        },
        /**
         * 保留6位小数 最后0则不显示
         * @param tim
         * @returns {number}
         */
        $formatZero(tim) {
            tim = tim || 0
            try {
                tim = tim.toFixed(6) + ''
                tim = tim.replace('.000000', '')
                if (tim.indexOf('.') > 0) {
                    tim = tim.match(/.*[1-9]/)[0]
                }
                return Number(tim)
            } catch (e) {
                console.log(e.message)
                return 0
            }
        },
        $getAlyForm(ossSelf, file) {
            const getSuffix = function(fileName) {
                const pos = fileName.lastIndexOf('.')
                let suffix = ''
                if (pos !== -1) {
                    suffix = fileName.substring(pos)
                }
                return suffix
            }
            const formData = new FormData()

            const filename = new Date().getTime() + getSuffix(file.name)
            // 注意formData里append添加的键的大小写
            formData.append('key', ossSelf.dir + filename) // 存储在oss的文件路径
            formData.append('OSSAccessKeyId', ossSelf.accessid) // accessKeyId
            formData.append('policy', ossSelf.policy) // policy
            formData.append('Signature', ossSelf.signature) // 签名
            formData.append('file', file)
            formData.append('fileName', filename)
            // 如果是base64文件，那么直接把base64字符串转成blob对象进行上传就可以了
            formData.append('success_action_status', 200) // 成功后返回的操作码
            return formData
        },
        $toStr(val) {
            if (!val) {
                return ''
            }
            return val
        },
        //成功信息
        $notifySuccess(msg){
            this.$notify({
                title: '成功',
                message: msg,
                type: 'success'
            });
        },
        //警告信息
        $notifyWarning(msg){
            this.$notify({
                title: '警告',
                message: msg,
                type: 'warning'
            });
        },
        //普通信息
        $notifyInfo(msg){
            this.$notify({
                title: '提示',
                message: msg,
                type: 'info'
            });
        },
        //错误信息
        $notifyError(msg){
            this.$notify({
                title: '错误',
                message: msg,
                type: 'error'
            });
        },
        // 返回信息提示
        $resJudge(dataInfo) {
            if (dataInfo.code === 200) {
                return true
            }
            return false
        },
        /**
         * 序列化数据
         * @param object
         */
        $serialize(object){
            return JSON.parse(JSON.stringify(object));
        },
        /**
         * 根据对应key 删除 val等于key的数据
         * @param list
         * @param key 对应数组key
         * @param val 值
         */
        $listDelByKey(list,key,val){
            let ols = list.map(i=>i[key]== val)
            if(ols.length>0){
                for (let i = 0; i <list.length ; i++) {
                    if(list[i][key]==val){
                        list.splice(i,1);
                        return this.$listDelByKey(list,key,val)
                    }
                }
            }else {
                return list;
            }
        },
        datedifference(d1, d2) {
            d1 = new Date(this.$formatDate(d1))
            d2 = new Date(this.$formatDate(d2))
            const dateSpan = d1 - d2
            const iDays = Math.floor(dateSpan / (24 * 3600 * 1000))
            return iDays
        },
        // 闰年判断
        isLeapYear(Year) {
            if (((Year % 4) == 0) && ((Year % 100) != 0) || ((Year % 400) == 0)) {
                return (true)
            } else {
                return (false)
            }
        },
        fmoney(s, n) {
            // let s = '12345237987584564656,000.00';
            // let d=s.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")
            n = n > 0 && n <= 20 ? n : 2
            s = parseFloat((s + '').replace(/[^\d\.-]/g, '')).toFixed(n) + ''
            const l = s.split('.')[0].split('').reverse()
            const r = s.split('.')[1]
            let t = ''
            for (let i = 0; i < l.length; i++) {
                t += l[i] + ((i + 1) % 3 == 0 && (i + 1) != l.length ? ',' : '')
            }
            return t.split('').reverse().join('') + '.' + r
        },
        /**
         * 计算时间差 并返回处理结果
         * @param d1 时间1
         * @param d2 时间2
         */
        $timeDiffStr(d1, d2) {
            let tile = d1.getTime() - d2.getTime()
            tile = parseInt(tile / 1000)
            const d = parseInt(tile / 24 / 60 / 60)
            const hs = tile - (24 * 60 * 60 * d)
            const h = parseInt(hs / 60 / 60)
            const ms = hs - (60 * 60 * h)
            const m = parseInt(ms / 60)
            const ss = ms - (60 * m)
            const s = ss % 60

            if (tile <= 60) {
                return s + '秒'
            } else if (tile <= 3600) {
                return m + '分' + s + '秒'
            } else if (tile <= 3600 * 24) {
                return h + '时' + m + '分' + s + '秒'
            } else {
                return d + '天' + h + '时' + m + '分' + s + '秒'
            }
        },
        $fakeClick(obj) {
            const ev = document.createEvent('MouseEvents')
            ev.initMouseEvent('click', true, false, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null)
            obj.dispatchEvent(ev)
        },
        $exportRaw(name, data) {
            const urlObject = window.URL || window.webkitURL || window
            const export_blob = new Blob([data])
            const save_link = document.createElementNS('http://www.w3.org/1999/xhtml', 'a')
            save_link.href = urlObject.createObjectURL(export_blob)
            save_link.download = name
            this.$fakeClick(save_link)
        },
        /**
         * 文件get下载
         * @param url 请求URL
         * @param name 文件名称
         */
        $downLoadByUrl(url, name) {
            // 获取token 自定义headers
            const token = ''
            const thx = this
            const xhr = new XMLHttpRequest()
            // GET请求,请求路径url,async(是否异步)
            xhr.open('GET', url, true)
            // 设置请求头参数的方式,如果没有可忽略此行代码
            xhr.setRequestHeader('token', token)
            xhr.setRequestHeader('Content-type', 'application/force-download')
            // 设置响应类型为 blob
            xhr.responseType = 'blob'
            // 关键部分
            xhr.onload = function(e) {
                // 如果请求执行成功
                if (this.status === 200) {
                    const blob = this.response
                    thx.$exportRaw(name, blob)
                }
            }
            // 发送请求
            xhr.send()
        },
        $download(url, filename) {
            var oReq = new XMLHttpRequest()
            const token = ''
            oReq.open('GET', url, true)
            oReq.responseType = 'blob'
            oReq.setRequestHeader('token', token)
            oReq.onload = function(oEvent) {
                var content = oReq.response

                var elink = document.createElement('a')
                elink.download = filename
                elink.style.display = 'none'

                var blob = new Blob([content])
                elink.href = URL.createObjectURL(blob)

                document.body.appendChild(elink)
                elink.click()

                document.body.removeChild(elink)
            }
            oReq.send()
        },
        /**
         * 取值 list<map<value,text>>
         * @param array list<map<value,text>>
         * @param key value
         * @returns {*} text
         */
        $getListData(array, key) {
            try {
                for (let i = 0; i < array.length; i++) {
                    if (array[i].value == key || array[i].value == (key + '')) {
                        return array[i]
                    }
                }
            } catch (e) {
                console.log(e.message)
            }
            return {}
        },
        /**
         * 判断文件是否为图片
         * @param url
         * @returns {boolean}
         */
        $isAssetTypeAnImage(url) {
            const index = url.lastIndexOf('.')
            const ext = url.substr(index + 1)
            return ['png', 'jpg', 'jpeg', 'bmp', 'gif', 'webp', 'psd', 'svg', 'tiff'].indexOf(ext.toLowerCase()) !== -1
        },
        /**
         * 对象转url参数
         * @param {*} data
         * @param {*} isPrefix
         */
        $queryParams(data, isPrefix) {
            if(typeof data !== 'object'){
                return '/'+data
            }
            isPrefix = isPrefix || false
            const prefix = isPrefix ? '?' : ''
            const _result = []
            for (const key in data) {
                const value = data[key]
                // 去掉为空的参数
                if (['', undefined, null].includes(value)) {
                    continue
                }
                if (value.constructor === Array) {
                    value.forEach((_value,i) => {
                        _result.push(encodeURIComponent(key) + i+'=' + encodeURIComponent(_value))
                    })
                } else {
                    _result.push(encodeURIComponent(key) + '=' + encodeURIComponent(value))
                }
            }

            return _result.length ? prefix + _result.join('&') : ''
        },
        $formatDateDay(value) {
            if (value === null || value === '') return ''
            const date = new Date(value)
            const y = date.getFullYear()
            let MM = date.getMonth() + 1
            MM = MM < 10 ? ('0' + MM) : MM
            let d = date.getDate()
            d = d < 10 ? ('0' + d) : d
            let h = date.getHours()
            h = h < 10 ? ('0' + h) : h
            let m = date.getMinutes()
            m = m < 10 ? ('0' + m) : m
            let s = date.getSeconds()
            s = s < 10 ? ('0' + s) : s
            return y + '-' + MM + '-' + d
        },
        /**
         * 获取时间  HH:mm：ss
         * @param timeString
         * @returns {{hours: number, seconds: number, minutes: number}}
         */
        $getTimeComponents(timeString) {
            // 创建一个新的 Date 对象，将时间字符串解析为日期
            const date = new Date(timeString);

            // 获取小时、分钟和秒，并格式化它们
            const hours = String(date.getHours()).padStart(2, '0'); // 将小时格式化为两位数
            const minutes = String(date.getMinutes()).padStart(2, '0'); // 将分钟格式化为两位数
            const seconds = String(date.getSeconds()).padStart(2, '0'); // 将秒格式化为两位数

            // 返回格式化后的时间字符串
            return `${hours}:${minutes}:${seconds}`;
        },
        $isAndroid(){
            return ( typeof AndroidFunction !== 'undefined');
        },
        /**
         * 查询数字包含某种值，包含就删除 不包含就添加
         * @param arr
         * @param value
         */
        $toggleValueInArray(arr, value) {
            const index = arr.indexOf(value); // 查找值在数组中的索引

            if (index !== -1) {
                // 如果找到了该值，则从数组中删除
                arr.splice(index, 1);
            } else {
                // 如果未找到该值，则添加到数组中
                arr.push(value);
            }
        },
        /**
         * 列表对象替换或者添加
         * @param arr
         * @param item
         * @param key 默认id
         * @returns {*}
         */
        $listObjectReplacementOrAddition(arr,item,key='id'){
            let existingObject =  arr.find(obj => obj[key] === item[key]);
            if (existingObject) {
                // 如果已经存在相同id的对象，更新其值
                Object.assign(existingObject, item);
            } else {
                // 如果不存在相同id的对象，添加新对象到数组中
                arr.push(item);
            }
            return arr;
        },
        /**
         * 格式化 YYYY-MM-DD
         * @param inputDate
         * @returns {string}
         */
        $formatToYYYYMMDD(inputDate) {
            const year = inputDate.getFullYear();
            const month = String(inputDate.getMonth() + 1).padStart(2, '0'); // 月份从0开始，需要加1
            const day = String(inputDate.getDate()).padStart(2, '0');
            return `${year}-${month}-${day}`;
        },
        /**
         * 获取上午和下午的时间范围
         * @param inputTime
         * @returns {string}
         */
        $isMorningOrAfternoon(inputTime) {
            // 获取当前时间
            const currentTime = new Date();

            // 将传入的时间字符串解析为 Date 对象
            const inputDate = new Date(inputTime);

            // 检查传入的时间是否有效
            if (isNaN(inputDate.getTime())) {
                return '无效时间';
            }

            // 获取当前日期的年、月、日
            const currentYear = currentTime.getFullYear();
            const currentMonth = currentTime.getMonth();
            const currentDay = currentTime.getDate();

            // 获取传入时间的年、月、日
            const inputYear = inputDate.getFullYear();
            const inputMonth = inputDate.getMonth();
            const inputDay = inputDate.getDate();

            if (
                currentYear === inputYear &&
                currentMonth === inputMonth &&
                currentDay === inputDay
            ) {
                // 传入的时间和当前时间是同一天
                // 获取上午和下午的时间范围
                const morningStartTime = new Date(currentTime);
                morningStartTime.setHours(0, 0, 0, 0); // 当天凌晨 00:00:00
                const morningEndTime = new Date(currentTime);
                morningEndTime.setHours(11, 59, 59, 999); // 当天上午 11:59:59

                const afternoonStartTime = new Date(currentTime);
                afternoonStartTime.setHours(12, 0, 0, 0); // 当天下午 12:00:00
                const afternoonEndTime = new Date(currentTime);
                afternoonEndTime.setHours(23, 59, 59, 999); // 当天晚上 23:59:59

                // 检查传入的时间是否在上午或下午的时间范围内
                if (inputDate >= morningStartTime && inputDate <= morningEndTime) {
                    return i18n.global.t(`battle.thisMorning`)
                } else if (inputDate >= afternoonStartTime && inputDate <= afternoonEndTime) {
                    return i18n.global.t(`battle.thisAfternoon`)
                }
            }

            return this.$formatToYYYYMMDD(inputDate); // 不是同一天或不是上午或下午，返回 yyyy-MM-dd 格式的日期字符串

        },
        /**
         *  把一个列表里面相同的对象合并在一起并记录个数 然后生成一个新列表
         * @param inputList
         * @param key 对应记录的key
         * @returns {*[]}
         */
        $mergeAndCountObjects(inputList,key='count') {
            const result = [];
            const objectMap = new Map();

            // 遍历输入列表中的每个对象
            for (const obj of inputList) {
                // 将对象序列化为字符串，以便用作 Map 的键
                const objString = JSON.stringify(obj);

                // 如果对象在 Map 中已经存在，则增加计数
                if (objectMap.has(objString)) {
                    objectMap.set(objString, objectMap.get(objString) + 1);
                } else {
                    // 否则，将对象添加到结果列表并设置计数为 1
                    objectMap.set(objString, 1);
                    result.push(obj);
                }
            }

            // 将计数添加到结果列表的每个对象
            for (const obj of result) {
                const objString = JSON.stringify(obj);
                obj[key] = objectMap.get(objString);
            }

            return result;
        }


    }
}
