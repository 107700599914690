/**
 * 游戏规则
 * 用户协议
 * 常见问题
 */
export default {
    /**
     * 追梦规则
     */
    ruleDream:{
        name:"ruleDream",
        zh:"1.选择您想要的目标饰品，选择成功率5%-75%。追梦所需金币会随饰品价值和所选百分比区间进行变化。{br}{br}" +
            "2每次追梦皆为独立计算，通过随机函数得到某个数值，与当前饰品价值乘以玩家控制百分比范围得到的所占用范围区间进行比对，若随机得到的数值与所在道具区间一致，则获得该饰品，若失败则获得随机安慰饰品。{br}{br}"+
            "3.追梦成功，您将获得目标饰品。追梦失败，您将获得随机安慰奖。{br}{br}"+
            "4.由于每次追梦为独立计算，当您连续三次的30%概率开启追梦饰品时不意味著有90%几率获得该饰品。当您连续3次30%都未掉落饰品时，第四次修改为10%概率开启不意味着100%获得饰品，相比于之前的30%获得几率可能会面临更大的失败概率。{br}{br}"+
            "5.请仔细查阅《用户协议》及相应条款，保持理性消费!",
        en: "1. Select the trinket you want to target with a selection success rate of 5%-75%. The F-bucks required to follow your dreams will vary with the value of the trinket and the percentage range selected. {br}{br} " +
            "2 Each Dream Chase is calculated independently, and a certain value is obtained through a random function, which is compared to the occupied range interval obtained by multiplying the current trinket value by the player's control percentage range; if the randomly obtained value agrees with the prop interval in which it is located, the trinket is awarded, and if it fails, the trinket is awarded as a random consolation trinket. {br}{br}" +
            "3. Chase the dream successfully, you will obtain the target trinket. If you fail to follow your dream, you will receive a random consolation prize. {br}{br}" +
            "4. Since each Dream Chase is calculated independently, when you open the Dream Chase Trinket for three consecutive times with 30% probability it does not mean that there is a 90% chance of obtaining the Trinket. When you fail to drop the trinket for 3 consecutive times at 30%, the fourth change to 10% probability of opening does not imply a 100% chance of obtaining the trinket, and you may face a greater probability of failure compared to the previous 30% chance of obtaining the trinket. {br}{br}" +
            "5. Please carefully review the User Agreement and the corresponding terms and conditions to maintain rational consumption!",
        tr:"",
    },
    /**
     * 对战规则
     */
    ruleBattle:{
        name:"ruleBattle",
        zh:"1、创建活动时，可以选择拼箱人数（2/4人），一个场次最多可以选择 6 个盲盒。{br}{br}" +
            "2、活动参与费用即本场活动开启的盲盒价格总和，支付费用后，即视为加入房间，中途退出房间，并不会退还盲盒或参与费。{br}{br}" +
            "3、多个玩家拼箱，并列胜出者各拿各的饰品，落败方饰品分解成福袋均分给胜出者。{br}{br}" +
            "4、每场活动的结果受到（创建时间，用户ID，用户位置和开始时间等）多方面因素影响，为完全随机产生的结果。游戏完全公平可证。",
        en:"1. When creating an event, you can choose the number of people who will be in the box (2/4 people), and you can choose up to 6 blind boxes for a field. {br}{br}"+
            "2. Once a room is created, it cannot be canceled. If no user joins within 15 minutes, the room will be automatically dissolved and the corresponding coins of the room will be returned. {br}{br}"+
            "3. The participation fee for the event is the total price of the blind boxes opened in this event. After paying the fee, you are considered to have joined the room and will not be refunded for the blind boxes or the participation fee if you exit the room in the middle of the day. {br}{br}"+
            "4. Multiple players spell the box, the tied winners each take their own jewelry, and the losing party's jewelry is broken down into bags of goodies to be divided equally among the winners. {br}{br}" +
            "5. The result of each event is affected by a variety of factors (creation time, user ID, user location and start time, etc.) and is a completely randomized result. The game is completely fair and verifiable.",
        tr:"",
    },
    /**
     * 用户协议
     */
    usrAgreement:{
        name:'usrAgreement',
        zh:"与使用服务相关的条件{br}{br}" +
            "无论您的父母或监护人是否同意使用本服务，十八（18）岁以下的个人均不得使用本服务。{br}{br}" +
            "您需要受支持的Web浏览器才能访问服务。您承认并同意lucsbox可能会停止支持特定的Web浏览器，并且您若要继续使用本服务，将需要下载受支持的Web浏览器。您还承认并同意，服务的性能取决于您的计算机设备和互联网连接的性能。{br}{br}" +
            "您同意通过Valve Corporation提供的Steam帐户登录并注册服务。您全权负责管理您的帐户和密码并对您的密码保密。您还全权负责限制对您帐户的访问。{br}{br}" +
            "您同意对您自己或其他人在您的帐户上或通过使用您的密码进行的所有活动负责。如果您认为第三方能够获取您的密码，请尽快使用本服务的密码重新生成功能来获取新密码。在任何情况下，您同意不允许任何第三方使用或访问本服务。{br}{br}" +
            "本条件文本中的所有术语和定义均由双方根据新加坡法律和互联网上使用的相关术语的解释规则进行解释。{br}{br}" +
            "作为您使用服务的条件，您同意不会：{br}{br}" +
            "冒充或歪曲您与任何个人或实体的关系；{br}{br}" +
            "访问、篡改或使用服务或lucsbox计算机系统的任何非公共区域；{br}{br}" +
            "尝试破译、反编译、反汇编、逆向工程或以其他方式调查用于提供服务的任何软件或组件；{br}{br}" +
            "以任何方式伤害或威胁伤害其他用户，或干扰或试图干扰任何用户、主机或网络的访问，包括但不限于通过发送病毒、过载、洪泛、垃圾邮件或邮件轰炸服务；{br}{br}" +
            "提供属于第三方的支付信息；{br}{br}" +
            "以滥用方式使用服务，违反其预期用途、lucsbox政策和指示以及任何适用法律；{br}{br}" +
            "系统地从服务中检索数据或其他内容，以直接或间接地在单个或多个下载、集合、编译、数据库、目录等中创建或编译，无论是通过手动方法、通过使用机器人、爬虫程序或蜘蛛或其他；{br}{br}" +
            "以违反第三方（例如G2A或Valve）提供服务运营所需设施和技术的条款和条件的方式使用服务；{br}{br}" +
            "在使用或访问本服务时侵犯第三方知识产权，包括但不限于通过使用本服务提供虚拟物品；{br}{br}" +
            "使用、宣传、连接或提供对lucsbox自行判断认为具有冒犯性或对lucsbox声誉造成损害的材料的访问权限，包括但不限于非法内容和色情内容以及被视为冒犯或伤害lucsbox的内容和/或服务（例如Warez网站、IRC机器人和Bittorent网站）。{br}{br}" +
            "终止{br}{br}" +
            "我们可以出于任何原因（包括但不限于您违反本条款）立即终止或暂停对我们服务的访问，恕不另行通知，也不承担任何责任。{br}{br}" +
            "本条款中根据其性质应在终止后继续有效的所有条款应在终止后继续有效，包括但不限于所有权条款、保证免责声明、赔偿和责任限制。{br}{br}" +
            "我们可能出于任何原因（包括但不限于您违反本条款）立即终止或暂停您的帐户，恕不另行通知，也不承担任何责任。{br}{br}" +
            "终止后，您使用服务的权利将立即终止。如果您想终止您的帐户，您只需停止使用该服务即可。{br}{br}" +
            "开案规则{br}{br}" +
            "打开箱子即表示您同意获取页面上显示的游戏物品之一以及箱子所含物品的描述。自动确定接收项目。{br}{br}" +
            "1.接受游戏物品{br}{br}" +
            "1.1.接受游戏物品即表示您同意箱子的价格（包括其中包含的游戏物品）符合您的期望，并确认您无权向网站/服务或公司索取任何额外赔偿。{br}{br}" +
            "1.2.一旦通过使用保护壳收到游戏物品并显示在个人帐户中，用户就可以将游戏物品带到Steam帐户{br}{br}" +
            "1.3. Steam帐户中游戏项目的接受取决于用户是否满足网站和个人帐户中指定的Steam和个人帐户设置。{br}{br}" +
            "禁止赌博禁止以任何违反任何法律或法规（包括禁止非法赌博的法律或法规）的方式使用lucsbox。购买的游戏物品只能用于个人目的，不得用于任何类型的商业利润。它必须用于其预期目的，即用于玩CS:GO。您必须根据Steam服务订户协议使用皮肤。您不得在第三方市场上转售在lucsbox上收到的皮肤，或以其他方式将此类皮肤换成任何有价值的东西。{br}{br}" +
            "2.拒绝游戏物品{br}{br}" +
            "2.1.用户可以在开箱时拒绝游戏物品，并将其退回以获得奖励积分，从而在将来支付箱子时给予个人帐户中物品描述中指定的金额折扣。{br}{br}" +
            "其他网站的连接{br}{br}" +
            "我们的服务可能包含非lucsbox所有或控制的第三方网站或服务的连接。{br}{br}" +
            "lucsbox无法控制任何第三方网站或服务的内容、隐私政策或做法，也不承担任何责任。您进一步承认并同意，对于因使用或依赖任何此类内容、商品或服务而造成或声称造成的任何损害或损失，lucsbox不承担直接或间接的责任。任何此类网站或服务。{br}{br}" +
            "我们强烈建议您阅读您访问的任何第三方网站或服务的条款和条件以及隐私政策。{br}{br}" +
            "无保证{br}{br}" +
            "本站点「按原样」提供，不提供任何明示或暗示的陈述或保证。lucsbox不对本站点或本站点提供的信息和材料做出任何陈述或保证。在不影响前述段落一般性的情况下，lucsbox不保证：本站点将持续可用或完全可用；或本站点上的信息完整、真实、准确或无误导性。本站点上的任何内容均不构成或无意构成任何类型的建议。如果您需要有关任何法律、财务或医疗问题的建议，您应该咨询适当的专业人士。{br}{br}" +
            "账户安全{br}{br}" +
            "本站点使用Steam OpenID，这意味着您正在使用您的Steam帐户登录。{br}{br}" +
            "您不得共享您的Steam帐户或登录信息，也不得让任何其他人访问您的Steam帐户或执行任何可能危及您的Steam帐户安全的行为。{br}{br}" +
            "如果您意识到或有理由怀疑任何违反安全的行为，包括但不限于Steam登录信息的任何丢失、被盗或未经授权的披露，您必须立即联系Steam支持（https://help.steampowered.com/）。{br}{br}" +
            "您全权负责维护Steam帐户信息的机密性，并且您将对Steam登录信息的所有使用（包括购买）负责，无论是否经过您授权。{br}{br}" +
            "您对通过您的Steam帐户发生的任何事情负责。{br}{br}" +
            "联系{br}{br}" +
            "我们绝不隶属于Valve公司、《反恐精英：全球攻势》、Steam或Valve公司的任何其他商标，也不受其认可。{br}{br}" +
            "退货和退款政策{br}{br}" +
            "lucsbox钱包通过充值您的lucsbox帐户钱包金额，即表示您接受收到的积分（标有IS标志）不值真钱，并且不会获得任何退款。{br}{br}" +
            "一旦订单确认并发送产品，我们不会为数字产品退款。如果您在接收或下载我们的产品时遇到任何问题，我们建议您联系我们寻求帮助。{br}{br}" +
            "不可抗力{br}{br}" +
            "对于因lucsbox合理控制之外的原因而导致的任何延迟或未能履行，我们不承担任何责任。{br}{br}" +
            "这包括但不限于由于不可预见的情况或我们无法控制的原因（例如天灾、战争、恐怖主义、骚乱、禁运、民事或军事当局的行为、火灾、洪水、事故、罢工或短缺）而未能履行本协议运输设施、燃料、能源、劳动力或材料。{br}{br}" +
            "协议的修改{br}{br}" +
            "我们有权单方面更改本协议的条款，这些更改在新版本的协议在互联网上发布时生效，网址为https://lucsbox.com/rule/privacyAgreement{br}{br}" +
            "继续使用本站点的功能将意味着您同意新版本协议的条款。如果您不同意新版本协议的条款，您必须停止使用本站点。{br}{br}" +
            "版权和其他无形权利{br}{br}" +
            "网站由Steam（Valve Corporation的注册商标）提供支持。{br}{br}" +
            "Valve、Valve徽标、Steam徽标、Counter-Strike和Counter-Strike徽标是Valve Corporation的商标和/或注册商标。{br}{br}" +
            "所有其他商标均为其各自所有者的财产。网站上的任何无形权利或其他材料均不会转让给用户。{br}{br}" +
            "在此网站/APP中的任何活动Apple不是赞助者，也没有以任何形式参与活动。{br}{br}" +
            "运输/交货政策{br}{br}" +
            "1、您在lucsbox网站获得的皮肤会显示在您的皮肤物品库存中lucsbox库存状态分为4种，分别为（库存、提货、退回入库、提货）{br}{br}" +
            "物品库存：显示您在开箱中获得的未分解的皮肤，您可以分解它们或请求拾取它们；{br}{br}" +
            "取货：代表您已提交，网站管理员尚未处理发货或因缺货等原因尚未协调到货源，这种状态需要您耐心等待。{br}{br}" +
            "退回存储：可能由以下原因引起：您的steam状态异常导致交易限制；或连接失败，或因市场缺货，所以无法处理您的取货请求；或因您的背包物品7天未领取，且价格波动过大，导致无法完成您的领取需求（所以请您及时领取饰品，以免给您的领取带来麻烦）。{br}{br}" +
            "已领取：代表您的提交已经通过审核，并且本站已经发货，这种状态需要您及时去steam平台领取您的装备。{br}{br}" +
            "2、提货前的准备工作{br}{br}" +
            "在领取装备之前，请前往您的个人资料页面设置您的Steam交易报价连接。但由于steam由于政策原因，中国玩家可能无法打开，还请使用UU网站加速器测试。{br}{br}" +
            "3、发货时间{br}{br}" +
            "正常情况下，您会在1分钟内收到取货通知，由于皮肤价格波动、库存等特殊情况，最长取货时间为18小时，请耐心等待。如有其他问题，请随时联系官方客服（10:00-22:00）{br}{br}" +
            "信息{br}{br}" +
            "lucsbox作为网站的管理员，采用这些使用条款，规定了用户的权利和义务，并构成对双方具有法律约束力的协议。这些使用条款影响用户在使用本站点时的权利并规定了某些义务，因此用户必须仔细阅读。{br}{br}" +
            "网站信息：lucsbox{br}{br}" +
            "邮箱：3681238276{at}qq.com{br}{br}",
        en:"Conditions relating to use of the Service{br}{br}" +
            "No individual under the age of eighteen (18) may use the Service, regardless of any consent from your parent or guardian to use the Service.{br}{br}" +
            "You need a supported Web browser to access the Service. You acknowledge and agree that lucsbox may cease to support a given Web browser and that your continuous use of the Service will require you to download a supported Web browser. You also acknowledge and agree that the performance of the Service is incumbent on the performance of your computer equipment and your Internet connection.{br}{br}" +
            "You agree to sign on and register for the Services through your Steam account provided by the Valve Corporation. You are solely responsible for managing your account and password and for keeping your password confidential. You are also solely responsible for restricting access to your account.{br}{br}" +
            "You agree that you are responsible for all activities that occur on your account or through the use of your password by yourself or by other persons. If you believe that a third party has access to your password, use the password regeneration feature of the Service as soon as possible to obtain a new password. In all circumstances, you agree not to permit any third party to use or access the Service.{br}{br}" +
            "All terms and definitions found in the text of the conditions are interpreted by the Parties in accordance with the laws of the Singapore and the rules of interpretation of the relevant terms used in the Internet.{br}{br}" +
            "As a condition to your use of the Service, you agree not to:{br}{br}" +
            "impersonate or misrepresent your affiliation with any person or entity;{br}{br}" +
            "access, tamper with, or use any non-public areas of the Service or lucsbox's computer systems;{br}{br}" +
            "attempt to decipher, decompile, disassemble, reverse engineer or otherwise investigate any of the software or components used to provide the Service;{br}{br}" +
            "harm or threaten to harm other users in any way or interfere with, or attempt to interfere with, the access of any user, host or network, including without limitation, by sending a virus, overloading, flooding, spamming, or mail-bombing the Service;{br}{br}" +
            "provide payment information belonging to a third party;{br}{br}" +
            "use the Service in an abusive way contrary to its intended use, to lucsbox policies and instructions and to any applicable law;{br}{br}" +
            "systematically retrieve data or other content from the Service to create or compile, directly or indirectly, in single or multiple downloads, a collection, compilation, database, directory or the like, whether by manual methods, through the use of bots, crawlers, or spiders, or otherwise;{br}{br}" +
            "make use of the Service in a manner contrary to the terms and conditions under which third parties provide facilities and technology necessary for the operation of the Service, such as G2A or Valve;{br}{br}" +
            "infringe third party intellectual property rights when using or accessing the Service, including but not limited to in making available virtual items by using the Service;{br}{br}" +
            "make use of, promote, link to or provide access to materials deemed by lucsbox at its sole discretion to be offensive or cause harm to lucsbox reputation, including, but not limited to, illegal content and pornographic content and content deemed offensive or injurious to lucsbox and/or the Service (such as Warez sites, IRC bots and bittorent sites).{br}{br}" +
            "Termination{br}{br}" +
            "We may terminate or suspend access to our Service immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.{br}{br}" +
            "All provisions of the Terms which by their nature should survive termination shall survive termination, including, without limitation, ownership provisions, warranty disclaimers, indemnity and limitations of liability.{br}{br}" +
            "We may terminate or suspend your account immediately, without prior notice or liability, for any reason whatsoever, including without limitation if you breach the Terms.{br}{br}" +
            "Upon termination, your right to use the Service will immediately cease. If you wish to terminate your account, you may simply discontinue using the Service.{br}{br}" +
            "Opening Case Rules{br}{br}" +
            "By opening case you agree to get the one of the game items presented on the page with the description with the case's containing. The item for receiving is determined automatically.{br}{br}" +
            "1. Accepting a game item{br}{br}" +
            "1.1. By accepting a game item you agree that the price of the case, including the game Item contained within, meets your expectations and confirm that you are not entitled to claim any additional compensation from website/service or company.{br}{br}" +
            "1.2. Once the game Item is received through the use of the Case and is shown in the Personal Account, the User has the possibility to take game Items to the Steam account{br}{br}" +
            "1.3. Acceptance of the game Item in the Steam account is subject to the User's fulfilment of the Steam and Personal Account settings specified on the Site and in the Personal Account.{br}{br}" +
            "Prohibition of gambling is forbidden to use lucsbox in any way that violates any law or regulation, including those prohibiting illegal gambling. The purchased game Items can only be used for personal purposes and shall not be used for any type of commercial profit. It must be used for their intended purpose, that is, for playing CS:GO. You must use the Skins in accordance with the Steam Service Subscriber Agreement. You will not resell the Skins received on lucsbox on third-party marketplaces or otherwise trade such Skins for any thing of value.{br}{br}" +
            "2. Refuse a game item{br}{br}" +
            "2.1. User can refuse the game item at the time of opening case and return it for bonus points, giving a discount when paying for cases in the future, in the amount specified in the description of the Item in the Personal Account.{br}{br}" +
            "Links To Other Web Sites{br}{br}" +
            "Our Service may contain links to third-party web sites or services that are not owned or controlled by lucsbox.{br}{br}" +
            "lucsbox has no control over, and assumes no responsibility for, the content, privacy policies, or practices of any third party web sites or services. You further acknowledge and agree that lucsbox shall not be responsible or liable, directly or indirectly, for any damage or loss caused or alleged to be caused by or in connection with use of or reliance on any such content, goods or services available on or through any such web sites or services.{br}{br}" +
            "We strongly advise you to read the terms and conditions and privacy policies of any third-party web sites or services that you visit.{br}{br}" +
            "No warranties{br}{br}" +
            "This website is provided “as is” without any representations or warranties, express or implied. lucsbox makes no representations or warranties in relation to this website or the information and materials provided on this website. Without prejudice to the generality of the foregoing paragraph, lucsbox does not warrant that: this website will be constantly available, or available at all; or the information on this website is complete, true, accurate or non-misleading. Nothing on this website constitutes, or is meant to constitute, advice of any kind. If you require advice in relation to any legal, financial or medical matter you should consult an appropriate professional.{br}{br}" +
            "Account security{br}{br}" +
            "This website using Steam OpenID, which means that you are login in with your Steam account.{br}{br}" +
            "You shall not share your Steam Account or the Login Information, nor let anyone else access your Steam Account or do anything else that might jeopardize the security of your Steam Account.{br}{br}" +
            "In the event you become aware of or reasonably suspect any breach of security, including without limitation any loss, theft, or unauthorized disclosure of the Steam Login Information, you must immediately contact Steam Support (https://help.steampowered.com/).{br}{br}" +
            "You are solely responsible for maintaining the confidentiality of the Steam Account Information, and you will be responsible for all uses of the Steam Login Information, including purchases, whether or not authorized by you.{br}{br}" +
            "You are responsible for anything that happens through your Steam Account.{br}{br}" +
            "Affiliation{br}{br}" +
            "We are in NO WAY affiliated with or endorsed by the Valve corporation, Counter Strike: Global Offensive, Steam or any other trademarks of the Valve corporation.{br}{br}" +
            "Returns and Refunds Policy{br}{br}" +
            "lucsbox Wallet By topping up the amount of your lucsbox account wallet, you accept that the credits received (labeled by the IS-sign) are not worth real money and not subject to any refunds.{br}{br}" +
            "We do not issue refunds for digital products once the order is confirmed and the product is sent. We recommend contacting us for assistance if you experience any issues receiving or downloading our products.{br}{br}" +
            "Force Majeure{br}{br}" +
            "We shall not be liable for any delay or failure to perform resulting from causes outside the reasonable control of lucsbox.{br}{br}" +
            "This is include without limitation any failure to perform hereunder due to unforeseen circumstances or cause beyond our control such as acts of God, war, terrorism, riots, embargoes, acts of civil or military authorities, fire, floods, accidents, strikes, or shortages of transportation facilities, fuel, energy, labor or materials.{br}{br}" +
            "Amendment of the Agreement{br}{br}" +
            "We has the right to unilaterally change the terms of the Agreement, and these changes come into force at the time of publication of the new version of the Agreement on the Internet at https://lucsbox.com/rule/privacyAgreement{br}{br}" +
            "Continued use of the functions of the Site will mean that you agree with the terms of the new version of the Agreement. If the you does not agree with the terms of the new version of the Agreement, you must cease to use the Site.{br}{br}" +
            "Copyright and other incorporeal rights{br}{br}" +
            "Website is Powered by Steam, a registered trademark of Valve Corporation.{br}{br}" +
            "Valve, the Valve logo, the Steam logo, Counter-Strike and the Counter-Strike logo are trademarks and/or registered trademarks of Valve Corporation.{br}{br}" +
            "All other trademarks are property of their respective owners. No incorporeal rights or other material on the website is transferred to the user.{br}{br}" +
            "{br}{br}" +
            "Apple is not a sponsor of any activities on this website/APP, nor does it participate in any form in the activities.{br}{br}" +
            "Shipping/delivery policy{br}{br}" +
            "1、The skins you get in the lucsbox website will be shown in your item inventory of skins lucsbox inventory status is divided into 4 types, respectively (inventory, picking up , Return to storage, picked up){br}{br}" +
            "Item inventory : shows the skins you have obtained in the opening case and not decomposed, you can either decompose them or request to pick them up;{br}{br}" +
            "Picking up : On behalf of you have submitted, the site administrator has not yet processed the shipment or due to shortage of goods and other reasons have not yet coordinated to the source, this state requires you to wait patiently.{br}{br}" +
            "Return to storage: may be caused by the following reasons: your steam status abnormal resulting in transaction restrictions; or link failure, or because the market is out of stock, so can not handle your pickup request; or because your backpack items not picked up for 7 days, and the price fluctuations are too large, resulting in the inability to complete your pickup needs (so please pick up the jewelry in time to avoid causing trouble for your pickup).{br}{br}" +
            "Picked up: on behalf of your submission has passed the audit, and the site has been shipped, this state requires you to go to the steam platform in time to pick up your equipment.{br}{br}" +
            "2、Preparation needed before picking up the equipment{br}{br}" +
            "Before picking up the equipment, please go to your profile page to set your steam trading offer link. But because steam due to policy reasons, Chinese players may not be able to open, but also please use UU Website Accelerator test.{br}{br}" +
            "3、Shipping time{br}{br}" +
            "Normal conditions, you will get the pickup notice within 1 minute, due to skins price fluctuations, inventory and other special circumstances, the maximum pickup time is 18 hours, please wait patiently. Any other questions, please feel free to contact the official customer service (10:00-22:00){br}{br}" +
            "Information{br}{br}" +
            "lucsbox as an administrator of the Website adopts these Terms of Use that specifies User’s rights and obligations and constitute a legally binding agreement for both parties. These Terms of Use affect User’s rights and impose certain obligations while using the Website, so the User must read them carefully.{br}{br}" +
            "Registration info: lucsbox{br}{br}" +
            "Email：-------------------{br}{br}" +
            "Address: -----------------------------{br}{br}",
        tr:"",
    },
    /**
     * 隐私协议
     */
    privacyAgreement:{
        name:'privacyAgreement',
        zh:"隐私政策条款{br}{br}" +
            "lucsbox可能会不时更改本隐私政策，一旦修订后的隐私政策在本应用程序上可用，更改就会生效。「最后更新」日期位于本政策底部。因此，请您在每次提交个人信息时参考本隐私政策。{br}{br}" +
            "数据控制者和所有者{br}{br}" +
            "lucsbox{br}{br}" +
            "联系方式：客服{br}{br}" +
            "收集的数据类型{br}{br}" +
            "本应用程序自行或通过第三方收集的个人数据类型包括：Cookie和使用数据。{br}{br}" +
            "收集的其他个人数据可能会在本隐私政策的其他部分中进行描述，或者通过与数据收集相关的专用说明文本进行描述。{br}{br}" +
            "个人数据可由用户自由提供，或在使用本应用程序时自动收集。{br}{br}" +
            "除非另有说明，本应用程序或本应用程序使用的第三方服务的所有者对Cookie或其他跟踪工具的任何使用，均用于识别用户并记住他们的偏好，其唯一目的是提供用户。{br}{br}" +
            "未能提供某些个人数据可能会导致本应用程序无法提供服务。{br}{br}" +
            "用户对通过本应用程序获取、发布或共享的任何第三方个人数据负责，并确认他们已获得第三方同意向所有者提供数据。{br}{br}" +
            "处理数据的方式和地点{br}{br}" +
            "加工方法{br}{br}" +
            "数据控制者以适当的方式处理用户的数据，并应采取适当的安全措施来防止未经授权的访问、披露、修改或未经授权的销毁数据。{br}{br}" +
            "数据处理是使用计算机和/或IT支持的工具进行的，遵循与指定目的严格相关的组织程序和模式。除了数据控制者之外，在某些情况下，参与网站运营的某些类型的负责人（管理、销售、营销、法律、系统管理）或外部各方（例如第三方）也可以访问数据。如有必要，由所有者指定为数据处理者。数据控制者可以随时向数据控制者请求这些方的更新列表。{br}{br}" +
            "地方{br}{br}" +
            "数据在数据控制者的运营办公室以及参与处理的各方所在的任何其他地方进行处理。如需更多信息，请联系数据控制者。{br}{br}" +
            "保留时间{br}{br}" +
            "数据将在提供用户请求的服务或本文档中概述的目的所规定的服务所需的时间内保留，并且用户始终可以请求数据控制者暂停或删除数据。在任何情况下，数据的保存时间均不会超过用户在网站上进行最新活动后的5年。{br}{br}" +
            "我们如何保护您的信息？{br}{br}" +
            "我们致力于保护您的隐私，并在个人数据的处理和传输方面实施各种安全措施。然而，互联网的性质使得数据在某些情况下可能在没有完全安全措施的情况下通过网络流动，并且可能被未经授权的人员访问。{br}{br}" +
            "我们如何使用您的信息？{br}{br}" +
            "我们将出于我们的合法利益使用您的个人信息，以响应您的询问、向您发送信息、履行对您的任何合同义务、出于研究目的、向您发送有关我们的商品、服务和相关机会的营销信息，以及经您明确同意的其他合理用途。{br}{br}" +
            "如果您从我们这里购买商品或服务，我们可能会通过邮寄、推送通知或电子邮件向您发送与您的购买相关的更多信息。{br}{br}" +
            "我们希望通过推送通知和电子邮件向您发送营销信息。{br}{br}" +
            "如果您不希望收到上述任何信息，也不希望我们按照上述方式与您联系，您可以向我们的客服询问。{br}{br}" +
            "如果您通过我们的联系表向我们提交您的意见，我们可能会在内部分发它们以用于培训和管理目的。{br}{br}" +
            "我们定期审查所收集的个人信息，以确定其准确性以及是否可以删除。{br}{br}" +
            "我们可能会将您发送给我们的信息与其他数据进行汇总（以便无法从该数据中识别您的身份），并将该汇总数据用于管理目的。{br}{br}" +
            "推送通知{br}{br}" +
            "该应用程序可以向用户发送推送通知。{br}{br}" +
            "分析{br}{br}" +
            "本节中包含的服务使所有者能够监控和分析网络流量，并可用于跟踪用户行为。{br}{br}" +
            "谷歌分析（谷歌公司）{br}{br}" +
            "Google Analytics是Google Inc.（「Google」）提供的一项网络分析服务。谷歌利用收集的数据来跟踪和检查该应用程序的使用情况，准备有关其活动的报告并与其他谷歌服务共享。{br}{br}" +
            "谷歌可以使用收集的数据来将其自己的广告网络的广告情境化和个性化。{br}{br}" +
            "收集的个人数据：Cookie和使用数据。{br}{br}" +
            "Google AdWords转化跟踪（Google Inc.）{br}{br}" +
            "Google AdWords转化跟踪是Google Inc.提供的一项分析服务，它将来自Google AdWords广告网络的数据与在此应用程序上执行的操作连接起来。{br}{br}" +
            "收集的个人数据：Cookie和使用数据。{br}{br}" +
            "Twitter广告转化跟踪（Twitter，Inc.）{br}{br}" +
            "Twitter广告转化跟踪是Twitter，Inc.提供的一项分析服务，它将来自Twitter广告网络的数据与在此应用程序上执行的操作连接起来。{br}{br}" +
            "收集的个人数据：Cookie和使用数据。{br}{br}" +
            "用户的权利{br}{br}" +
            "用户可以对所有者处理的数据行使某些权利。{br}{br}" +
            "特别是，用户有权执行以下操作：{br}{br}" +
            "随时撤回他们的同意。用户有权撤回之前同意处理其个人数据的同意。反对处理其数据。如果处理是在法律依据而非同意的基础上进行的，用户有权反对对其数据的处理。下面的专门部分提供了更多详细信息。访问他们的数据。用户有权了解数据所有者是否正在处理数据、获取有关处理某些方面的披露并获取正在处理的数据的副本。核实并寻求整改。用户有权验证其数据的准确性并要求更新或更正。限制对其数据的处理。在某些情况下，用户有权限制对其数据的处理。在这种情况下，所有者不会出于存储以外的任何目的处理其数据。删除或以其他方式删除他们的个人数据。在某些情况下，用户有权要求所有者删除其数据。接收他们的数据并将其传输到另一个控制器。用户有权以结构化、常用和机器可读的格式接收其数据，并且在技术上可行的情况下，有权将其无障碍地传输到另一个控制器。如果数据是通过自动方式处理的，并且该处理是基于用户的同意、用户所签订的合同或合同前的义务，则本规定适用。提出投诉。用户有权向其主管的数据保护机构提出索赔。{br}{br}" +
            "定义和法律参考{br}{br}" +
            "个人数据（或数据）{br}{br}" +
            "直接、间接或与其他信息相关的任何信息可以识别或识别自然人。此类信息可能包括姓名、地址、护照或身份证数据、个人身份号码以及任何其他可以共同识别个人身份的数据。{br}{br}" +
            "使用数据{br}{br}" +
            "通过本站点自动收集的信息，其中可能包括：使用本站点的用户所使用的计算机的IP地址或域名、URI地址（统一资源标识符）、请求时间、提交请求所使用的方法对服务器的请求、响应中收到的文件的大小、指示服务器应答状态的数字代码（成功结果、错误等）、原产国、浏览器的功能以及所使用的操作系统用户、每次访问的各种时间详细信息（例如，在网站内每个页面上花费的时间）以及有关网站内所遵循路径的详细信息，特别是访问页面的顺序，以及有关设备操作系统的其他参数和/或用户的IT环境。{br}{br}" +
            "用户{br}{br}" +
            "除非另有说明，使用本站点的个人与数据主体一致。{br}{br}" +
            "数据主体{br}{br}" +
            "个人数据所指的自然人。{br}{br}" +
            "数据处理器（或数据管理器）{br}{br}" +
            "如本隐私政策所述，代表控制者处理个人数据的自然人或法人、公共机构、代理机构或其他机构。{br}{br}" +
            "数据控制者（或所有者）{br}{br}" +
            "单独或与他人共同确定个人数据处理的目的和方式（包括与本站点的运营和使用有关的安全措施）的自然人或法人、公共机构、代理机构或其他团体。除非另有说明，数据控制者是本站点的所有者。{br}{br}" +
            "这个应用程序{br}{br}" +
            "收集和处理用户个人数据的方式。{br}{br}" +
            "服务{br}{br}" +
            "本站点提供的服务如相关条款（如果有）和本站点上所述。{br}{br}" +
            "欧盟（或欧盟）{br}{br}" +
            "除非另有说明，本文件中所有提及欧盟的内容均包括欧盟和欧洲经济区的所有现有成员国。{br}{br}" +
            "Cookie{br}{br}" +
            "存储在用户设备中的小数据。{br}{br}" +
            "联系方式：在线客服{br}{br}" +
            "邮箱：3681238276{at}qq.com{br}{br}" +
            "最后更新时间：2024年4月11日{br}{br}",
        en:"Privacy Policy Terms{br}{br}" +
            "lucsbox may change this Privacy Policy from time to time and the change will take effect once the revised Privacy Policy is available on this Application. The “last updated” date is at the bottom of this Policy. Therefore, please refer to this Privacy Policy each time you submit your personal information.{br}{br}" +
            "Data Controller and Owner{br}{br}" +
            "lucsbox{br}{br}" +
            "Owner contact information: customer service{br}{br}" +
            "Types of Data collected{br}{br}" +
            "Among the types of Personal Data that this Application collects, by itself or through third parties, there are: Cookies and Usage Data.{br}{br}" +
            "Other Personal Data collected may be described in other sections of this privacy policy or by dedicated explanation text contextually with the Data collection.{br}{br}" +
            "The Personal Data may be freely provided by the User, or collected automatically when using this Application.{br}{br}" +
            "Any use of Cookies - or of other tracking tools - by this Application or by the owners of third party services used by this Application, unless stated otherwise, serves to identify Users and remember their preferences, for the sole purpose of providing the service required by the User.{br}{br}" +
            "Failure to provide certain Personal Data may make it impossible for this Application to provide its services.{br}{br}" +
            "Users are responsible for any Personal Data of third parties obtained, published or shared through this Application and confirm that they have the third party's consent to provide the Data to the Owner.{br}{br}" +
            "Mode and place of processing the Data{br}{br}" +
            "Methods of processing{br}{br}" +
            "The Data Controller processes the Data of Users in a proper manner and shall take appropriate security measures to prevent unauthorized access, disclosure, modification, or unauthorized destruction of the Data.{br}{br}" +
            "The Data processing is carried out using computers and/or IT enabled tools, following organizational procedures and modes strictly related to the purposes indicated. In addition to the Data Controller, in some cases, the Data may be accessible to certain types of persons in charge, involved with the operation of the site (administration, sales, marketing, legal, system administration) or external parties (such as third party technical service providers, mail carriers, hosting providers, IT companies, communications agencies) appointed, if necessary, as Data Processors by the Owner. The updated list of these parties may be requested from the Data Controller at any time.{br}{br}" +
            "Place{br}{br}" +
            "The Data is processed at the Data Controller's operating offices and in any other places where the parties involved with the processing are located. For further information, please contact the Data Controller.{br}{br}" +
            "Retention time{br}{br}" +
            "The Data is kept for the time necessary to provide the service requested by the User, or stated by the purposes outlined in this document, and the User can always request that the Data Controller suspend or remove the data. In no event, the Data will be kept longer than 5 years after the latest activity of the User on the site.{br}{br}" +
            "How Do We Protect Your Information?{br}{br}" +
            "We are committed to protecting your privacy and we implement various security measures in relation to our processing and transfer of personal data. However, the nature of the Internet is such that the data may in some circumstances flow over networks without full security measures and could be accessible to unauthorized persons.{br}{br}" +
            "How Do We Use Your Information?{br}{br}" +
            "We will use your personal information for our legitimate interests to respond to your enquiries, to send information to you, to fulfil any contractual obligation to you, for research purposes, to send marketing information to you regarding our goods, services and related opportunities, and other reasonable uses by virtue of your affirmative consent.{br}{br}" +
            "If you purchase goods or services from us we may send you further information that is related to your purchase, by post, push notification or e-mail.{br}{br}" +
            "We would like to send you marketing information, by push notifications and e-mail.{br}{br}" +
            "If you do not wish to receive any of the above information or be contacted by us in accordance with the above, you can ask to our customer service.{br}{br}" +
            "If you submit your views to us via our contact form we may circulate them internally for training and management purposes.{br}{br}" +
            "We review the Personal Information we collect periodically to determine its accuracy and whether it can be deleted.{br}{br}" +
            "We may aggregate the information you send to us with other data (so that you cannot be identified from that data) and use that aggregated data for administrative purposes.{br}{br}" +
            "Push notifications{br}{br}" +
            "This Application may send push notifications to the User.{br}{br}" +
            "Analytics{br}{br}" +
            "The services contained in this section enable the Owner to monitor and analyze web traffic and can be used to keep track of User behavior.{br}{br}" +
            "Google Analytics (Google Inc.){br}{br}" +
            "Google Analytics is a web analysis service provided by Google Inc. (“Google”). Google utilizes the Data collected to track and examine the use of this Application, to prepare reports on its activities and share them with other Google services.{br}{br}" +
            "Google may use the Data collected to contextualize and personalize the ads of its own advertising network.{br}{br}" +
            "Personal Data collected: Cookies and Usage Data.{br}{br}" +
            "Google AdWords conversion tracking (Google Inc.){br}{br}" +
            "Google AdWords conversion tracking is an analytics service provided by Google Inc. that connects data from the Google AdWords advertising network with actions performed on this Application.{br}{br}" +
            "Personal Data collected: Cookies and Usage Data.{br}{br}" +
            "Twitter Ads conversion tracking (Twitter, Inc.){br}{br}" +
            "Twitter Ads conversion tracking is an analytics service provided by Twitter, Inc. that connects data from the Twitter advertising network with actions performed on this Application.{br}{br}" +
            "Personal Data collected: Cookies and Usage Data.{br}{br}" +
            "The rights of Users{br}{br}" +
            "Users may exercise certain rights regarding their Data processed by the Owner.{br}{br}" +
            "In particular, Users have the right to do the following:{br}{br}" +
            "Withdraw their consent at any time. Users have the right to withdraw consent where they have previously given their consent to the processing of their Personal Data. Object to processing of their Data. Users have the right to object to the processing of their Data if the processing is carried out on a legal basis other than consent. Further details are provided in the dedicated section below. Access their Data. Users have the right to learn if Data is being processed by the Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy of the Data undergoing processing. Verify and seek rectification. Users have the right to verify the accuracy of their Data and ask for it to be updated or corrected. Restrict the processing of their Data. Users have the right, under certain circumstances, to restrict the processing of their Data. In this case, the Owner will not process their Data for any purpose other than storing it. Have their Personal Data deleted or otherwise removed. Users have the right, under certain circumstances, to obtain the erasure of their Data from the Owner. Receive their Data and have it transferred to another controller. Users have the right to receive their Data in a structured, commonly used and machine readable format and, if technically feasible, to have it transmitted to another controller without any hindrance. This provision is applicable provided that the Data is processed by automated means and that the processing is based on the User's consent, on a contract which the User is part of or on pre-contractual obligations thereof. Lodge a complaint. Users have the right to bring a claim before their competent data protection authority.{br}{br}" +
            "Definitions and legal references{br}{br}" +
            "Personal Data (or Data){br}{br}" +
            "Any information that directly, indirectly, or in connection with other information allows for the identification or identifiability of a natural person. Such information may include name, address, passport or ID data, personal identification number, and any other data which collectively may allow to identify a person.{br}{br}" +
            "Usage Data{br}{br}" +
            "Information collected automatically through this Site, which can include: the IP addresses or domain names of the computers utilized by the Users who use this Site, the URI addresses (Uniform Resource Identifier), the time of the request, the method utilized to submit the request to the server, the size of the file received in response, the numerical code indicating the status of the server's answer (successful outcome, error, etc.), the country of origin, the features of the browser and the operating system utilized by the User, the various time details per visit (e.g., the time spent on each page within the Site) and the details about the path followed within the Site with special reference to the sequence of pages visited, and other parameters about the device operating system and/or the User's IT environment.{br}{br}" +
            "User{br}{br}" +
            "The individual using this Site who, unless otherwise specified, coincides with the Data Subject.{br}{br}" +
            "Data Subject{br}{br}" +
            "The natural person to whom the Personal Data refers.{br}{br}" +
            "Data Processor (or Data Supervisor){br}{br}" +
            "The natural or legal person, public authority, agency or other body which processes Personal Data on behalf of the Controller, as described in this privacy policy.{br}{br}" +
            "Data Controller (or Owner){br}{br}" +
            "The natural or legal person, public authority, agency or other body which, alone or jointly with others, determines the purposes and means of the processing of Personal Data, including the security measures concerning the operation and use of this Site. The Data Controller, unless otherwise specified, is the Owner of this Site.{br}{br}" +
            "This Application{br}{br}" +
            "The means by which the Personal Data of the User is collected and processed.{br}{br}" +
            "Service{br}{br}" +
            "The service provided by this Site as described in the relative terms (if available) and on this Site.{br}{br}" +
            "European Union (or EU){br}{br}" +
            "Unless otherwise specified, all references made within this document to the European Union include all current member states to the European Union and the European Economic Area.{br}{br}" +
            "Cookies{br}{br}" +
            "Small piece of data stored in the User's device.{br}{br}" +
            "Contact information: customer service{br}{br}" +
            "Email：-----------------------{br}{br}",
        tr:"",
    },
    /**
     * 关于我们
     */
    aboutUs:{
        name:'aboutUs',
        zh:"{h}关于我们{hs}" +
            "lucsbox是一个专业公正的CS:GO皮肤开箱网站，在lucsbox网站上，您可以立即获得更多的csgo皮肤，开箱以后皮肤可以随时放入库存中。lucsbox致力于为玩家提供更有趣的获取皮肤的方式，采用独特的获取模式，深受许多玩家喜爱。{br}{br}" +
            "{h}箱子介绍{hs}" +
            "lucsbox开箱肯定会掉落CS:GO皮肤，皮肤完全随机掉落，我公司坚持专业、公平的原则，为用户提供Steam游戏虚拟皮肤提取服务。特别提醒：消费需理性，娱乐为主。{br}{br}" +
            "{h}箱子规则{hs}" +
            "用户可以花费相应数量的金币根据不同类型的箱子进行开箱（金币是玩家充值获取，禁止提现）；箱子内的皮肤为用户随机获得，皮肤掉落的概率在皮肤图表中公示，本平台的箱子能获得的皮肤范围以网站具体图表为准。我们希望玩家遵守相关法律法规，保持理性消费，文明开箱。{br}{br}" +
            "官方站点地址：lucsbox {br} " +
            "联系信息：在线客服{br}" +
            "电子邮件：3681238276{at}qq.com {br}{br}",
        en: "{h}About Us{hs}" +
            "lucsbox is a professional and impartial CS:GO skins case website, in lucsbox website, you can instantly get more csgo skins,Cases has all kinds of skins can be obtained at any time after picking up to the steam inventory. lucsbox is committed to providing players with more interesting way to get skins, using a unique mode of acquisition, is loved by many players .{br}{br}" +
            "{h}Case introduction:{hs}" +
            "lucsbox case open will definitely drop CS:GO skins, and skins drop completely random, our company adheres to the principle of professional, fair to provide users with steam game virtual skins extraction services{br}{br}" +
            "{h}Special reminder{hs} " +
            "consumption needs to be rational, recreation for fun." +
            "Case rules: users can spend the corresponding amount of A coins to open the case according to the different types of cases (A coins for players to recharge to obtain, prohibit exchange); cases skins for users to obtain randomly, as well as the probability of skins drops are publicized on the skins chart, the case of this platform can get the range of skins to the specific chart in the website shall prevail. We hope that players will observe the relevant laws and regulations, keep rational consumption and open cases in a civilized manner.{br}{br}" +
            "Official website address: lucsbox{br}" +
            "Contact information: -----------------{br}" +
            "Company Name：--------------------------{br}" +
            "Address：-----------------------------{br}" +
            "Email: ---------------{br}{br}",
        tr:"",
    },
    questions:{
        name:'questions',
        zh:"{h}可以使用Steam钱包资金购买lucsbox箱子吗?{hs}" +
            "Steam余额不适用于本网站。您需要直接在lucsbox网站上充值余额。{br}{br}" +
            "{h}我已充值余额，但资金并未转入我的个人资料。 我应该怎么办？{hs}" +
            "你的付款可能会延迟 5-10 分钟。如果一小时内资金未转入，请联系支持人员，将付款详细信息写入lucsbox支持。{br}{br}" +
            "{h}你的取回已等待超过30天{hs}" +
            "在30天内没有取回的饰品将自动等值兑换为余额返至您的账户。{br}{br}" +
            "{h}因为弹窗死机我无法出售饰品！{hs}" +
            "关闭窗口并刷新该页面。如果问题依然存在，清理浏览器缓存后再尝试。{br}{br}" +
            "{h}买箱之前{hs}" +
            "检查自己的Steam账号市场交易权限是否受限，否则我们的机器人将无法发送皮肤至您的账号。登陆Steam账号打开市场，确认市场交易权限正常并且密码更新超过7天{br}{br}",
        en:"{h}Can I use Steam Wallet funds to buy lucsbox cases? {hs}" +
            "Steam balances do not apply to this site. You will need to top up your balance directly on the lucsbox website. {br}{br}" +
            "{h}I have topped up my balance but the funds have not been transferred to my profile. What should I do? {hs}" +
            "Your payment may be delayed by 5-10 minutes. If the funds are not transferred within an hour, please contact support to write the payment details to lucsbox Support. {br}{br}" +
            "{h}Your pickup has been pending for more than 30 days{hs}" +
            "Trinkets not retrieved within 30 days will be automatically converted to a balance equivalent to be returned to your account. {br}{br}" +
            "{h}I was unable to sell the trinket due to a dead pop-up window! {hs}" +
            "Close the window and refresh the page. If the problem persists, clear your browser cache and try again. {br}{br}" +
            "{h}Before buying a box{hs}" +
            "Check that your Steam account marketplace trading privileges are not restricted, otherwise our bots will not be able to send skins to your account. Log in to your Steam account and open the marketplace, make sure the marketplace trading privileges are working and that your password has been updated for more than 7 days{br}{br}",
        tr:"",
    }
}