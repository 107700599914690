<!--
时间:2023/8/15/015
用法:左侧导航栏
-->

<template>
  <div class='leftNav' id="leftNav">
    <div class="left">
左侧
    </div>
    <div class="right">


    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import {mapState} from "vuex";
import MenuList from "@/layout/MenuList.vue";

export default {
  name: "Nav",
  provide() {
    return {
      cdkShowFun: this.cdkShowFun,
      showDownload: this.showDownload,
      openQQ: this.openQQ,
    };
  },
  computed: {
    ...mapState(['userInfo', 'getByUrl']),
  },
  components: { MenuList,},
  data() {
    return {

    }
  },
  methods: {
    ...mapMutations(['setLoginShow', 'setCDK', 'setLoginShow', 'setActiveShow']),
  },
  mounted() {
    window.addEventListener('click', this.closeMenu)
  },
  beforeUnmount() {
    window.removeEventListener('click', this.closeMenu)
  }
}
</script>

<style scoped lang="scss">
.leftNav {
  width: var(--window-leftNav-width);
  height: calc(100% - var(--window-headerNav-height));
  overflow-y: auto;
  background-color: #1A1C24;
  display: inline-block;
  position: fixed;
  left: 0;
  top:var(--window-headerNav-height);
  z-index: 20;

}

@media screen and (max-width: $windowSize-md) {
  .headerNav {
    padding: 0 0.8rem;

  }
}
</style>