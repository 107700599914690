import
{
    ElCarousel,
    ElCarouselItem,
    ElLoading,
    ElDrawer,
    ElPagination,
    ElInfiniteScroll,
    ElSlider,
    ElTable,
    ElTableColumn
} from 'element-plus'


const elementUi = [
    ElCarousel,
    ElCarouselItem,
    ElDrawer,
    ElPagination,
    ElLoading,
    ElSlider,
    ElTable,
    ElTableColumn
]
const elementPlugin = [
    ElLoading,
    ElInfiniteScroll
]

// element组件全局注册
export const elementUiUse = (app) => {
    elementUi.forEach(component => {
        app.component(component.name, component)
    })
}

export const elementPluginUse = (app) => {
    elementPlugin.forEach(plugin => {
        app.use(plugin)
    })

}