import request from './index'
import {splitUrl} from "@/utils/math";


export const login = async () => {
    try {
        let params = splitUrl(window.location.href)

        console.log(params)
        let data = await request({
            url: '/api/steam/steamLogin',
            method: 'POST',
            data: params
        })
        localStorage.setItem('token', data.token)
        return Promise.resolve(data)
    } catch (error) {
        return Promise.reject(error)
    }
}


export const getUserInfo = () => {
    return request({
        url: '/api/user/tokenByInfo',
        method: 'GET'
    })
}


//获取steam登陆跳转链接
export const getSteamLoginUrl = (params) => {
    return request({
        url: '/api/steam/getSteamLoginUrl',
        method: 'GET',
        params
    })
}

//steam回调的登陆
export const steamLogin = (data) => {
    return request({
        url: '/api/steam/steamLogin',
        method: 'POST',
        data
    })
}

//注册
export const register = (data) => {
    return request({
        url: '/api/user/register',
        method: 'POST',
        data
    })
}
//获取邮箱验证码
export const getEmailCode = (data) => {
    return request({
        url: '/api/user/send/emailCode',
        method: 'POST',
        data
    })
}

//邮箱注册
export const emailRegister = (data) => {
    return request({
        url: '/api/user/emailRegister',
        method: 'POST',
        data
    })
}
//邮箱登陆
export const emailLogin = (data) => {
    return request({
        url: '/api/user/emailLogin',
        method: 'POST',
        data
    })
}

//找回邮箱密码
export const emailForget = (data) => {
    return request({
        url: '/api/user/forget/password/email',
        method: 'POST',
        data
    })
}


//邮箱注册
export const phoneRegister = (data) => {
    return request({
        url: '/api/user/register',
        method: 'POST',
        data
    })
}


export const phoneLogin = (data) => {
    return request({
        url: '/api/user/login',
        method: 'POST',
        data
    })
}

//找回邮箱密码
export const phoneForget = (data) => {
    return request({
        url: '/api/user/update/password',
        method: 'POST',
        data
    })
}

//获取手机验证码
export const getVerification = (data) => {
    return request({
        url: '/api/user/send/verification',
        method: 'POST',
        data
    })
}

/**
 * 获取支付列表
 *
 * @param {Object} data - 暂无
 * @returns {Promise} 返回一个 Promise 对象
 */
export const getRechargeConfig = (data) => {
    return request({
        url: '/api/pay/rechargeConfig',
        method: 'POST',
        data
    });
};
/**
 * vip用户列表
 *
 * @returns {Promise} 返回一个 Promise 对象
 */
export const getVipUserList = () => {
    return request({
        url: '/api/pay/vipUserList',
        method: 'POST',
    });
};

/**
 * 获取红包
 *
 * @param {Object} data - 参数
 * @param {Object} data.id - id
 * @returns {Promise} 返回一个 Promise 对象
 */
export const getRedPackage = (data) => {
    return request({
        url: '/api/pay/getRedPackage',
        method: 'POST',
        data
    });
};

/**
 * 获取红包
 *
 * @param {Object} params - 参数
 * @param {Object} params.url - 当前网站域名,不带协议
 * @returns {Promise} 返回一个 Promise 对象
 */
export const getByUrl = (params) => {
    if (!params.url) {
        params.url = process.env.VUE_APP_WEBSITE
    }
    return request({
        url: '/api/channel/getByUrl',
        method: 'GET',
        params
    });
};
/**
 * 提交支付  返回三方支付连接
 *
 * */

export const submitPay = (params) => {
    return request({
        url: '/api/pay/online/scan',
        method: 'GET',
        params
    })
}
/**
 * 获取支付列表
 *
 * @param {Object} params - 暂无
 * @returns {Promise} 返回一个 Promise 对象
 */
export const getVipList = (params) => {
    return request({
        url: 'apis/user/vip/list',
        method: 'POST',
        params
    });
};

/**
 * CDK红包
 *
 * @param {String} data - 暂无
 * @returns {Promise} 返回一个 Promise 对象
 */
export const openCDK = (data) => {
    return request({
        url: '/api/cdkRed/open/' + data,
        method: 'POST'
    })
}

/**
 * 交易市场，获取列表
 *
 * @param {Object} params - 请求参数
 * @param {string} params.dictChildId - 字典子ID
 * @param {string} params.skinItem - 皮肤项
 * @param {number} params.pageNum - 页码
 * @param {number} params.pageSize - 每页数量
 * @param {number} params.maxPrice - 最大价格
 * @param {number} params.minPrice - 最小价格
 * @param {string} params.sort - 排序方式 DESC降序，ASC升序
 * @param {string} params.name - 名称
 * @param {number} params.newFlag - 新标志
 * @returns {Promise} 返回一个 Promise 对象
 */
export const getMarketList = (params) => {
    return request({
        url: 'api/mall/getPage',
        method: 'GET',
        params
    });
};

/**
 * 交易市场，兑换饰品
 *
 * @param {number} params.id - 饰品id
 * @returns {Promise} 返回一个 Promise 对象
 */
export const marketBuySkin = (params) => {
    return request({
        url: 'api/mall/buySkin/' + params.id,
        method: 'GET',
        params,
        getUserInfo: true,
    });
};

/**
 * 实名认证
 *
 * @param {String} data.name - 姓名
 * @param {String} data.idCard - 身份证号码
 * @returns {Promise} 返回一个 Promise 对象
 */
export const checkIdCard = (data) => {
    return request({
        url: '/api/user/checkIdCard',
        data,
        method: 'POST'
    })
}