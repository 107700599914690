import axios from 'axios'
import {createDialog} from '@/components/Message'
import store from '@/store'
import {ElLoading} from "element-plus";
import {defaultLanguage, languageObj} from "@/common/config";
import {encodeAesString, decodeAesString, setDefaultAesKeyInfoIv} from "@/common/encryption";
import {replaceSkinNameWithMarketHashName} from "@/axios/httpUtil";

const instance = axios.create({timeout: 1000 * 10})
instance.defaults.headers.post['Content-Type'] = 'application/json'
instance.defaults.baseURL = process.env.VUE_APP_BASE_API;

// 添加一个请求锁变量
let lockKey = ''
let loading = '';
let loadingLockKey = 'loadingLock';
let language = localStorage.getItem('language') || defaultLanguage
let isPost = false
instance.interceptors.request.use((config) => {
    //方法锁
    if (config.lock) {
        lockKey = config.url;
        let isRequesting = localStorage.getItem(lockKey);
        if (isRequesting && isRequesting === "true") {
            return Promise.reject(new Error('lock'));
        } else {
            localStorage.setItem(lockKey, "true")
        }
    }

    //loading 状态开启
    if (config.loading !== false) {
        loading = ElLoading.service({
            lock: false,
            background: 'rgba(0, 0, 0, 0.7)',
        });
        localStorage.setItem(loadingLockKey, "true")
    }
    const token = localStorage.getItem("token")
    config.headers['content-language'] = language
    if (token) {
        config.headers.token = token
    }

    if (process.env.VUE_APP_CACHE === "true" && config.data) {
        // if(config.data){
        //生产日志打印
        if (typeof CSLog !== 'undefined') {
            CSLog(config.url, config.data, process.env.VUE_APP_CACHE === "true")
        }
        isPost = true;
        let iv = new Date().getTime() + "000";
        setDefaultAesKeyInfoIv(iv);
        config.data = {data: encodeAesString(JSON.stringify(config.data)), time: iv};
    }

    return config;
}, error => Promise.reject(error))


/**
 * 延迟关闭
 * 关闭触发
 * @param msg
 */
function closeLock(msg) {
    if (loading) {
        //延迟 300ms
        setTimeout(() => {
            let item = localStorage.getItem(loadingLockKey);
            if (item == null) {
                loading.close()
            }
        }, 100);
        localStorage.removeItem(loadingLockKey)
    }
    if (lockKey && msg !== 'lock') localStorage.removeItem(lockKey)
}

let isTimeoutErrorShown = false; // 标志变量，表示是否已经显示了超时错误提示

instance.interceptors.response.use(
    res => {
        // 请求完成后，将请求锁设置为false，表示没有请求在进行
        closeLock('close')
        if (res.status === 200) {
            switch (res.data.code) {
                case 200:
                    let data = res.data.data;
                    replaceSkinNameWithMarketHashName(data, language)
                    // 是否需要刷新用户信息
                    if(res.config.getUserInfo === true){
                        store.dispatch('actionUserInfo')
                    }
                    return Promise.resolve(data)
                case 8000:
                    createDialog.error(res.data.msg)
                    localStorage.removeItem('token')
                    localStorage.removeItem('fromPath')
                    store.commit('setUserInfo', {})
                    store.commit('setLoginShow', true)
                    return Promise.reject(res.data)
                default:
                    if(res.config.loading !== false){
                        createDialog.error(res.data.msg)
                    }
                    return Promise.reject(res.data)
            }
        }
    },
    error => {
        // 请求超时错误处理
        if (!isTimeoutErrorShown && error.code === 'ECONNABORTED' && error.message.indexOf('timeout') !== -1) {
            createDialog.error('请求超时，请重试')
            isTimeoutErrorShown = true; // 设置标志变量为 true，表示已经显示了提示
            setTimeout(() => { //设置定时器，避免多次弹窗
                isTimeoutErrorShown = false;
            }, 5000)
        }
        closeLock(error.message)
        return Promise.reject(error);
    }
);
export default instance
