import { createApp  } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vueI18n from './i18n'
import LazyLoad from 'vue3-lazyload'
import ClickOutside from "@/directive/ClickOutside";
import Decimal from 'decimal.js';
import 'animate.css';
import {elementUiUse,elementPluginUse} from  "@/plugins/element-plus"
import '@/assets/svgIcon' // svg 图标
// import V3Popup from "@/components/Message";
import 'element-plus/dist/index.css'
import 'swiper/css';
import "swiper/css/pagination";

import SocketService from '@/common/websocket.js'
import {setLanguage} from "@/utils/math";
setLanguage()


// 自定义工具
import toolUtil from '@/utils/ToolUtil'

const app = createApp(App);


// 对服务端进行websocket的连接
// if(store.state.token) SocketService.Instance.connect()

app.config.globalProperties.$socket = SocketService.Instance
// app.config.globalProperties.$V3Popup = V3Popup
//注册全局组件
elementUiUse(app) //注册全局elementUi
elementPluginUse(app)  //注册全局element插件
// 注册到全局

app.mixin(toolUtil)// 全局工具类
app.use(ClickOutside)
//语言切换
app.use(vueI18n) // 注册国家化语言 i18n
// 图片懒加载  <img v-lazy="require('图片路径')">
app.use(LazyLoad,{
    loading:require('./assets/common/boxLoading.png'), //图片加载展示
    // error:require('./assets/1693202371370.png') //图片挂掉展示
})

app.use(store).use(router).mount('#app')
