<!--
时间:2023/9/12/012
用法:手机端底部导航栏
-->

<template>
  <div class='TabBar _mobile' id="TabBar">
    <div class="item"
         v-for="(item,index) in list"
         :class="{
           'active':($route.path === item.path) || (item.path !== '/' && $route.path.indexOf(item.path) > -1),
         }"
         @click="goPath(item.path)"
         :key="index">
      <div class="imgBox">
        <img :src="($route.path === item.path || item.path !== '/' && $route.path.indexOf(item.path) > -1) ? item.imgActive : item.img"
            alt="">
      </div>
      <div class="name">
        {{ $t(`nav.${item.name}`) }}
      </div>
    </div>
  </div>
</template>

<script>
import {goPath} from "@/router/goPath";

export default {
  name: "TabBar",
  data() {
    return {
      list: [
        {
          name: 'battle',
          path: '/battle',
          img: require('../assets/icon/Battles@2x.png'),
          imgActive: require('../assets/icon/dh4-b@2x.png'),
        },
        {
          name: 'rollRoom',
          path: '/roll',
          img: require('../assets/icon/roll@2x.png'),
          imgActive: require('../assets/icon/dh2-b@2x.png'),
        },
        {
          name: 'blindBox',
          path: '/',
          img: require('../assets/icon/box@2x.png'),
          imgActive: require('../assets/icon/dh1-b@2x.png'),
        },
        {
          name: 'dream',
          path: '/dreams',
          img: require('../assets/icon/Dreams@2x.png'),
          imgActive: require('../assets/icon/dh3-b@2x.png'),
        },
        {
          name: 'information',
          path: '/profile',
          img: require('../assets/icon/wode.png'),
          imgActive: require('../assets/icon/wodeActive.png'),
        },
      ]
    }
  },
  methods: {
    goPath
  }
}
</script>

<style scoped lang="scss">
.TabBar {
  height: var(--mobil-tabbar-height);
  background: url("../assets/mobileIcon/dh-bj@2x.png ") no-repeat center/31.25rem 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
  z-index: 666;
  padding-bottom: var(--mobil-tabbar-padding-bottom);
  box-sizing: border-box;

  .item {
    width: 20%;
    display: flex;
    flex-direction: column;
    padding-bottom: 0.5rem;
    justify-content: center;
    align-items: center;

    .imgBox {
      @include center;
      transition: all 0.2s linear;

      img {
        height: 2rem;
      }
    }

    .name {
      /*display: none;*/
      font-size: 1rem;
      color: rgba(255, 255, 255, 0.6);
      font-weight: bold;
      margin-top: 0.1rem;
      text-align: center;
    }
  }

  .active {
    .name {
      color: $font-color1;
      display: block;
    }
  }


}
</style>
