import rule from "@/i18n/rule";

export default {
    nav: {
        // 导航栏
        welfare: "Bonus",
        mySkins: 'Eşyalarım',
        myWelfare: 'Bonuslarım',
        myReward: 'Komisyonum',
        information: "ki̇şi̇sel merkez",
        historicalBilling: 'İşlemler',
        login: "Giriş",
        logout: "Çıkış",
        blindBox: 'Kasa',
        rollRoom: "Roll Odası",
        dream: "Hayallerin peşinde",
        battle: "Kasa savaşı",
        bomb: "Bomba İmha",
        refill: "Yükleme",
        // 登陆弹窗
        loginTitle: 'csbox\'i kullanabildiğinizden emin olmamız gerekiyor, lütfen onaylayın:',
        my18: '18 yaşından büyük olduğumu onaylıyorum.',
        agree: "Aşağıdakileri okudum ve kabul ediyorum:",
        usrAgreement: 'Kullanıcı Sözleşmesi',
        privacyAgreement: 'Gizlilik Sözleşmesi',
        antiMoneyLaundering: 'Para Aklamayı Önleme Anlaşması',
        // 电脑版footer内容
        openCases: 'Toplam açılan kasa sayısı',
        users: 'Kayıtlı kullanıcılar',
        online: 'Çevrimiçi kullanıcılar',
        footerText1: "Kasa açmanın çeşitli yolları var, ayrıca eşya çıkarmak güvenli ve kolay!",
        // 手机版侧边栏
        games: "Oyun",
        preferential: "Özel teklif",
        free: "Ücretsiz",
        recharge: "Yükleme",
        cdk: "CDK",
        other: "Diğerleri",
        QQ: "QQ",
        custServ: "Müşteri Hizmetleri",
        App: "App",
        glblMusicEnabled: "Ses Efektleri Kapatıldı",
        soundEffectsDisabled: "Ses Efektleri Açıldı",
        questions: "Sıkça Sorulan Sorular",
        aboutUs: "Hakkımızda",
        pleaseEntrANickname: "Bir Takma Ad Girin",
        pleaseEntrYourEmailAddr: "Lütfen cep telefonu numaranızı girin",
        pleaseEntrTheVerificationCd: "Bir Doğrulama Kodu Girin",
        obtainVerificationCd: "Doğrulama Kodu Alın",
        pleaseOutputTheInptPsw: "Lütfen Şifre Girin",
        pleaseConfThePsw: "Lütfen Şifreyi Tekrar Girin",
        pleaseEntrTheInvtCd: "Davet Kodunu Girin (Opsiyonel)",
        noSteam: "Steam Hesabınız Yok mu?",
        emailLogin:"Cep telefonu numarası girişi",
        passwordLogin:"şifre girişi",
        verificationCdLogin:"CAPTCHA Girişi",
        register: "Kayıt Ol",
        steamLogin: "Steam ile Giriş",
        forgetPsw: "Şifreni mi unuttun?",
        conf: "Onayla",
        retrievePsw: "Şifre Kurtar",
        ruleDream: "Hayallerin peşinden koşmak için kurallar",
        exchange:"dönüştürülebilir",
        inptCdk:"Lütfen CDK şifresini girin",
        redemptionSuccessful: "Başarılı kefaret, A-coins"
    },
    blindBox: {
        //开箱页面
        open: "Aç",
        buy: "Satın Al",
        bestDrp: "Son Düşüşler",
        boxContains: "Kasa şunları içerir",
        buyTips1: "Kasa açmak için yeterli bakiyeniz yok!",
        mbrDrp:"会员掉落",
        anchorDrp:"主播掉落",
    },
    roll: {
        ofcl: "Resmi",
        anchor: "Yayıncı",
        iParticipatedIn: "Katıldığım",
        haveInHand: "Devam Eden",
        itsOvr: "Bitmiş",
        participated: "nişanlı",
        viewingRooms: "Oda Görüntüle",
        joinNow: "Hemen Katıl",
        deadline: "Bitiş Zamanı",
        totVal: "Toplam Değer",
        items: "Eşyalar",
        players: "Oyuncu",
        //roll详情
        countdownToLottery: "Çekiliş Geri Sayımı",
        prtpConditions: "Katılım Koşulları",
        includingPrizes: "Ödüller içerir",
        participatingUsers: "Katılımcılar",
        awrdLst: "Kazananlar",
        joinRm: "Odaya Katıl"
    },
    dream: {
        chooseAccessories: "Eşya Seç",
        probability: "Olasılık",
        upg: "Rüyalar",
        upgTips: "*Rüyalar olasılığı aralığı: {num1}%~{num2}%",
        //追梦选择饰品弹窗
        inputPlaceholder1: "Anahtar kelimeyi ara",
        minPrc:'最低价',
        maxPrc:'最高价',
    },
    battle: {
        //对战列表
        allBattles: "Tüm Savaşlar",
        rankingLst: "Sıralama",
        historicalRecords: "Kayıt Geçmişi",
        myRecords: "Kayıtlarım",
        creABattle: "Savaş Oluştur",
        //创建对战
        addBox: "Kasa Ekle",
        robot: "Bot",
        combatCost: "Savaş maliyeti",
        createBattle: "Savaş Oluştur",
        chooseAnItem: "Eşya Seçin",
        incorrectNumberOfPeople: "Kişi sayısı hatalı",
        inputPlaceholder1: "Kasa anahtar kelimesini girin",
        thisMorning: "Bu sabah",
        thisAfternoon: "Bu öğleden sonra",
        selected: "Seçilen: {num} adet",
        coVal: "Toplam maliyet",
        //对战详情
        ready: "Hazır",
        drawnJewelry: "Eşya çekiliyor",
        obtainJewelry: "Eşya kazanıldı",
        //排行榜
        today: "Bugün",
        yesterday: "Dün",
        //对战结束弹窗
        battleIsOvr: "Savaş Sonu",
        winner: "Kazanan",
        oneMoreRound: "Aynı eşleşmeyi oluştur",
        confJoin: "Üyelik onaylandı mı?"
    },
    information: {
        //个人中心首页
        invtCd: "Davet Kodu",
        bal: 'Bakiye',
        rchg: "Yükleme",
        orderNumber: 'Sipariş Numarası',
        transactionLnk: "STEAM Ticaret Bağlantısı",
        steamLinkTips1: "Ticaret bağlantısı bir kez bağlandığında değiştirilemez, lütfen dikkatli olun！Tokenın aynı bağlantıyla güncellenmesinde herhangi bir sınır yoktur.",
        steamLinkTips2: "Başkalarının Steam ticaret bağlantısını bağlamayın.",
        steamInputPlaceholder1: "Steam ticaret bağlantınızı girin",
        obtain: "Al",
        saveAddr: "Adresi Kaydet",
        save: "Kaydet",
        mySkins: 'Eşyalarım',
        myWelfare: 'Bonuslarım',
        rchgDetails: "Yükleme Detayları",
        incomeDetails: "Gelir/Gider Detayları",
        myReward: 'Komisyonum',
        // 我的物品页面
        retrieving: "Geri Alınıyor",
        retrieveRecords: "Geri Alma Geçmişi",
        numOrnaments: "Toplam {num} adet eşya",
        obtainingTm: "Kazanma zamanı",
        val: "Değer",
        selectedNum: "Seçilen: {num} adet",
        retrieve: "Geri Al",
        chooseABox: "... kazanmak için kasa seçmeye git",
        noItems: "Henüz bir eşyan yok",
        noRecIsCur: "Şu anda bir kayıt yok",
        //佣金页面
        generalizationCd: "Promosyon Kodu",
        cpy: "Kopyala",
        promLnk: "Tanıtım Linki",
        partnerRules: "Şartlar ve kurallar",
        totNumOfInvitations: "Toplam davet edilen sayısı",
        effectiveUsers: "Geçerli Kullanıcılar",
        accumRewards: "Toplam Ödül",
        rewardRatio: "Ödül Oranı",
        lvlDsc: "Seviye açıklaması",
        invtRec: "Davet Kayıtları",
        date: "Tarih",
        invitee: "Davet Edilenler",
        usrRchg: "Kullanıcı Yükleme",
        myRewards: "Ödüllerim",
        rewardLvl: "Ödül Seviyesi",
        invitedUsrRchg: "Davet Edilen Kullanıcı Yükleme",
        promAndAcq: "Tanıtım yapan kullanıcılar şunları alacaktır:",
        incmStmt: "Kazanç ile İlgili Açıklama"
    },
    confirm: {
        success: "Başarılı",
        warning: "Uyarı",
        error: "Hata",
        noDataAvailable: "Veri Yok",
        //老虎机抽奖结果弹窗
        decompose: "Ayrıştır",
        decomposeAll: "Tümünü Ayrıştır",
        cont: "ile devam edin",
        //confirm提示弹窗
        confDecomposition: "{num} adet eşyayı ayrıştırmak istediğine emin misin?",
        common: "Toplam",
        cancel: "İptal",
        confirm: "Onayla",
        confDec: "Ayrıştırmayı onaylıyor musun?",
        confOpn: "Açılış onayı?",
        conf: "Onaylama?",
        //msg提示弹窗
        decomposeSuccess: "Ayrıştırma başarılı!",
        theMinimumProbabilityIs: "En düşük olasılık {num}",
        notLoggedIn: "Giriş yapılmadı",
        noJewelrySelected: "Eşya seçilmedi",
        agreeToATreaty: "Lütfen aşağıdaki şartları okuyup kabul edin",
        copySuccess: "Kopyalama Başarılı",
        copyError: "Kopyalama Başarısız",
        pleaseFollow: "Geri Alınıyor, lütfen bekleyin",
        notYetOpn: "Henüz açılmadı",
        glblMusic: "Lütfen önce tüm sesleri açın",
        amtCannotBeZero: "Tutar 0 olamaz",
        pswModSuccessful: "Şifre Değiştirme Başarılı",
        loginSuccessful: "Giriş Başarılı",
        loginWasSuccessful: "Kayıt başarılı, lütfen giriş yapın!",
        emailFmtErr: "Yanlış biçimlendirilmiş cep telefonu numarası",
        vSntSuccess: "Doğrulama kodu başarıyla gönderildi",
        pswInconsistency: "Şifreler aynı değil",
        entrTheVerificationCd: "Lütfen Doğrulama Kodunu Girin",
        theMinPswLenIs6: "Şifrenin uzunluğu en az 6 karakter olmalıdır.",
        pleaseEntrANickname: "Bir Takma Ad Girin",
    },
    //常用的
    common: {
        //单位
        day: 'Gün',
        hh: "Saat",
        mm: 'Dakika',
        ss: "Saniye",
        piece: "Adet",
        // title常用
        back: "Geri Dön",
        gameRules: "Oyun Kuralları",
        //表格常用
        players: "Oyuncu",
        price: "Fiyat",
        reset: "Sıfırla",
        round:"bout",
        cases: "Kasa",
        value: "Değer",
        actions: "İşlem",
        watch: "İzleme",
        join: "Katıl",
        ranking: "Sırala",
        bestDrp: "En İyi Düşen",
        date: "Tarih",
        st: "Durum",
        purp: "Kullanım",
        amtOfMoney: "Tutar",
        allElection: "Tümünü Seç",
        typ: "TİPOLOJİ",
        numericalVal: "sayısal değer",

    },
    incomeType: {
        //个人中心收支type
        whole: "Tümü",
        incm: "Gelir",
        expenditure: "Gider",
        purCurr: "Coin satın al",
        recovery: "Geri Al",
        unpacking: "Kasa Aç",
        commission: "Komisyon",
        redEnvelopes: "Hediye Paketi",
        war: "Savaş",
        shoppingMallPur: "Mağaza Alışverişi",
        chasingDreams: "Hayallerin peşinde",
        coinPurGft: "Coin Hediye Et",
        cshWdrl: "Para Çekme",
        rlRm: "Roll Odası",
        regGifts: "Kayıt Hediyesi",
        addRewards: "Ek Ödül",
        redEnvelopeReward: "Hediye Paketi Ödülü",
        luckyTurntable: "Çarkıfelek",
        bkOffcDistribution: "Arka Planda Dağıtım",
        skinSynthesis: "Skin Sentezi",
        sgnInReward: "Giriş Ödülü",
        newDreamChasing: "Yeni Hayallerin peşinde",
        unpackingKing: "Kasa Açma Kralı",
        skydiverHd: "Extrem skydiving",
        inviteRebate: "Davetiye iadesi",
        taskRewards: "Görev Ödülleri",
    },
    provably: {
        provably: "Adalet doğrulaması",
        provablyDsc: "Adillik Doğrulama Beyanı",
        text1: "1. Oyuncular her oynadıklarında bir zar puanı üretecek ve ilgili zar puanı ilgili deriyi alacaktır.",
        text2: "2, açık kutu kovalamaca rüyaları arena üç oyun her bir kullanıcı tohumu seti ve genel karma, modül içinde ortak, örneğin: açık kutu blok a kutusu ve b kutusu rl noktasında hiçbir fark yoktur, sadece cildi alın bir fark var.",
        text3: "3. İşlemde yer alan şifrelenmiş değerler aşağıdaki gibidir:",
        text4: "4, bu roll point algoritmasının açık kaynak kodunu ve test platformunu sağlıyoruz, programcı bilen arkadaşlar tek başına aritmetik yaparak ilgisiz web sunucuları durumunda sonuçların tutarlı olup olmadığını görebilirler.",
        clientSeed: "KullanıcıSeed",
        publicHash: "publicHash",
        round: "tur sayısı",
        secretHash: "gizli karma",
        secretSalt: "gizli tuz",
        createTime: "createTime",
        enterClientSeed: "Lütfen kullanıcı tohumunu girin",
        enterPublicHash: "Lütfen genel karmayı girin",
        enterSecretHash: "Lütfen gizli karmayı girin",
        enterSecretSalt: "Lütfen gizli tuzu girin",
        enterRound: "Lütfen tur sayısını girin",
        p1: "Yani, kullanıcının değiştirebileceği iki değer vardır ve bu iki değer, işlemin / yuvarlanma noktasının sonucunu değiştirebilen işlemde yer alır. Örneğin: Kişisel tohum varsayılan 1. Yuvarlanma noktası sonucunu 10086 olarak değiştirdikten sonra manuel olarak 2'ye değiştirin, diğer parametreler değişmeden kalır, ardından 1 sonucunu doğrulamak için aracı kullanmaya gidin, sonucun 10086 olmaması durumunda tohumun 1 olduğunu göreceksiniz ",
        p2: "Örnekte, tohumu veya genel hash'i değiştirdikten sonra, önceki oyunun gizli hash'ini ve gizli tuzunu görebilir ve ardından önceki sonucun doğru olup olmadığını doğrulayabilirsiniz. Önceki değerleri almak ve olasılık dağılımının daha büyük turlar için (örneğin 10w kez) makul olup olmadığını ölçmek de mümkündür.",
        opnSrcAddr: "açık kaynak adresi",
        download: "Çevrimdışı Doğrulayıcıyı İndirin",
        curSeed: "Güncel Tohumlar",
        preSeed: "previousServerSeed",
        chg: "değişim",
        reset: "reprovision",
        oneClkCpy: "Tek tıkla kopyalama",
        validator: "Adalet Doğrulayıcı",
        attachment1: "(İstenildiği zaman değiştirilebilir)",
        attachment2: "(sıfırlanabilir)",
        attachment3: "(Oynanan oyun sayısı ile birlikte artar)",
        resetDisp: "(Genel hash sıfırlandıktan sonra görüntülenir)",
        legitimateSeed: "Lütfen yasal tohumları girin",
        entrANewSeed: "Yeni tohumlar girin",
        seedReg: "Üç karakter (veya daha fazla) İngilizce harf, rakam veya Çince karakter",
        oneClkPaste: "tek tıklamayla yapıştırma",
        rlPnt: "yuvarlanma noktası",
        waitVerification: "Doğrulama için bekliyorum"
    },
    privacyClause:{
        usrAgreement:rule.usrAgreement.tr,
        privacyAgreement:rule.privacyAgreement.tr,
        aboutUs:rule.aboutUs.tr,
        questions:rule.questions.tr,
        ruleDream:rule.ruleDream.tr,
        ruleBattle:rule.ruleBattle.tr
    },
    217: 'Toplam yatırım {num} miktarına ulaştıktan sonra, her gün bir kez ücretsiz çekiliş yapma şansınız olacak. Yatırdığınız miktar ne kadar çoksa, kazanabileceğiniz ödül de o kadar iyi olur.',
    218: 'Sonraki yüklemeye %{num} bonus',
    // 十二星座
    238: "Koç",
    239: "Taurus",
    240: "İkizler",
    241: "Yengeç",
    242: "Aslan",
    243: "Başak",
    244: "Terazi",
    245: "Scorpio",
    246: "Boğa",
    247: "Oğlak",
    248: "Aquarius",
    249: "Pisces",

    250: "On İki Takımyıldızı",
    251: "Takımyıldızı Sırt Çantası",
    252: "Başlıyor",
    253: "Setlerin geri dönüşüm fiyatı",
    254: "Geri dönüşüm seti",
    255:"Lütfen parçalanma sayısını girin",
    256:"Bir set için yeterli değil, parçalayamıyorum!",
    257:"Memnuniyetle kabul ederim.",
}
