//rem自适应配置
export const remConfig = {
    configWidth: [ //configWidth 每一个对象代表一个窗口配置，请按照windowWidth大小排序
        {
            designWidth: 1920,
            windowWidth: 992,
            fontSize: 24,
        },
        {
            designWidth: 750,
            windowWidth: 0,
            fontSize: 24,
        }
    ]
}

//默认初始语言
export const defaultLanguage = "zh_CN"
// 语言切换
export const language = [
    {
        index: 1,
        languageName: "中文",
        language: 'zh_CN',
        nation: 'CN',
        flag: require('../assets/flag/lang-cn.svg')
    },
    /*{
        index: 2,
        languageName: "English",
        language: "en_US",
        nation: 'EN',
        flag: require('../assets/flag/lang-en.svg')
    },
    {
        index: 3,
        languageName: "Türkçe",
        language: "tr_TR",
        nation: 'TR',
        flag: require('../assets/flag/tuerqi.png')
    },*/
    /*  {
          index: 4,
          languageName: "Japan",
          language: "en_JP",
          nation: 'JP',
          flag: require('../assets/flag/lang-en.svg')
      },*/
]

//磨损度
export const exteriorList = [
    {en: 'Factory New', cn: '崭新出厂'},
    {en: 'Factory-New', cn: '崭新出厂'},
    {en: 'Minimal Wear', cn: '略有磨损'},
    {en: 'Minimal-Wear', cn: '略有磨损'},
    {en: 'Field-Tested', cn: '久经沙场'},
    {en: 'Well-Worn', cn: '破损不堪'},
    {en: 'Battle-Scarred', cn: '战痕累累'},
    {en: 'Not-Painted', cn: '无涂装'},
    {en: 'Not Painted', cn: '无涂装'}
]

export const languageObj = language.reduce((obj, item) => {
    obj[item.language] = item;
    return obj;
}, {});


//装备等级对应的颜色
export const skinBgColor = {
    0: '#FFA22A',
    1: "#CA002B",
    2: "#8800F4",
    3: "#2F6EFD",
    4: "#CFCFCF",
}

//steam社区
export const link = {
    steamcommunity: 'https://steamcommunity.com/login/home/?goto=%2Fid%2Fme%2Ftradeoffers%2Fprivacy#trade_offer_access_url'
}

export const QQNumber = '931699007'
//远程图片域名
export const oss = 'https://lucsbox.oss-cn-hangzhou.aliyuncs.com'


export const az = 'abcdefghijklmnopqrstuvwxyz'
export const AZ = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ'
export const To9 = '0123456789'
//用户注册随机名字
export const userNickname = [
    "怀念。", "宅一起", "追逐你", "掩耳〃", "__衰", "ㄗs鋒", "悲伤￠", "桑心。", "步行者", "断￠点", "迩回眸",
    "夏▄雨", "繁体字", "-装爱", "渡沧海", "逆光影", "浅色枫", "令狐臭", "玩寂寞", "喵骚年", "影子的泪", "夏丶陌汐",
    "歌淺婼離", "空城孤王", "发条兔子", "灵魂之神", "念你南笙", "临波望月", "乱世情缘", "海浪精灵", "泪芯伤痕", "孤独寒冰",
    "狱血魔神", "緘緘恋雨", "南柯无痕", "夜雨☆梦", "雨的印记", "相逢不易", "缱绻光纪", "遇强则强", "夏倦", "星沉", "清川",
    "梵声", "黑牌", "船梢", "闲鸥", "春蝉", "秦月", "风急", "钩吻", "江鬼", "叹软", "夜鹭", "岛风", "野趣", "鬼鸟", "甜俊",
    "学痞", "和风", "醉沙疆", "云中朵", "垂耳兔", "海上花", "蝴蝶结", "諵.笙", "弥桑花", "梦銮＊", "幻竹寰", "风萧萧", "洛婉儿",
    "云浅月", "温盏年", "顾璃曦", "苏绾落", "大宝宝", "紫鲽恋", "兔小琦", "萌琳达", "兔大萌", "一路繁华", "为你战苍穹", "梦灬惜丨月",
    "巅峰飞跃", "茕茕之心", "日光边境", "浮華亂世", "绝版小男人", "清风归客", "幽灵的尤物", "星残梦缺", "七夕汪", "月天↘临君", "玻璃年纪i",
    "陌小浅", "龙魂★东霸", "笙歌君独幽", "临界の星空", "半曲弦铩", "上帝也卖萌", "断桥り飞雪", "淡墨留痕", "半步天涯",
    "奔跑的尘埃", "琴音似君语", "长街有风", "御风踏歃血", "孤山幽姿", "夜间步履", "吞云吐雾", "堕落天使彡", "碧潭飞雪", "深巷猫与魂",
    "纵火焚雨", "鱼的第8秒", "独行莽荒", "手枪专业户", "祭念", "漫步云海涧", "淺唱離莂曲", "丛林仙踪", "偶遇一只猫", "残阳半夏",
    "一朵死亡花", "冰火№云海", "孤身战群狼", "小羊要吃狼", "紫藤夢", "雾月", "傲者自孤", "果味砒霜", "夏天飄雪", "孤者何惧", "执手不离",
    "清酒桃花", "薄荷微凉", "匆匆似梦", "可爱到炸", "萌翻全场", "我心永恒", "拥之则安", "懶得熱情", "为你着迷", "听风讲你", "別來無恙",
    "以风予你", "一人独醉", "温柔与你", "南笙北萧", "软耳兔兔", "无爱不欢", "画地为牢", "几分曾经", "眉眼带笑", "笑談心酸", "一味宠爱",
    "风与歌姬", "非沵不爱", "迣鎅忲徦", "梦屿千寻", "终于懂了", "一顾安歌", "烟酒为伴", "举杯独醉", "念于红尘", "捧脸亲亲", "旧梦虐心",
    "余温散尽", "几分喜欢", "心碎葬海", "深拥不弃", "凉城孤影", "在劫难逃", "顾你一世", "十里笙歌", "爱而不得", "一笑奈何", "無惡不作",
    "白衣煮茶", "初心未变", "难得矜持", "最暖的伴", "独久厌闹", "寄你情深", "深巷旧人", "逍遥浪子", "遇见美好", "挽手叙旧", "岁月静好",
    "我养你阿", "独衬心酸", "岁月无声", "乱世魔女", "嗨给狗逼看", "帅瞎你眼", "持刀走四方", "娇纵浪人", "烈女饮清酒", "枪指菊花", "逆袭★四爷",
    "国民帅比", "無知不是罪", "稳掌三界权", "浮浪人", "安稳不如野", "当個祖宗", "共我为王", "绿海猖狂", "一秒メ速杀", "帅裂苍穹", "帅届扛把子",
    "带着骄傲跑", "请笏靠近", "血屠手", "挽弓射天狼", "快到碗里来", "动刀不动情", "出刀收人头", "劳资女王范", "孤魂伴野鬼", "戲子帝王", "冷血范",
    "五杀♂教主", "拽的闪闪亮", "血影战神", "嗨翻世界", "闻风丧破胆", "三生石", "醉花阴", "洗尘衫", "我发光", "蓝眼泪", "白衬衫", "终欢乐", "眉眼笑",
    "梦若羽", "相思扣", "心甘罪", "森离九", "桃扇骨", "路终难", "战皆罪", "捧枯瓣", "饮孤酒", "绿袖子", "浅笑痕", "北秋悲", "共清欢", "客行舟",
    "山川志", "孤千羽", "酒言欢", "彼之岸", "水中月", "孤心湖", "醉若尘", "梦璃子", "顾别离", "青柠茶", "失忆梦", "音尘绝", "行雁书", "行云流",
    "无泪痕", "陌若惜", "晚风吟", "寒风吹", "岚荼盛", "离人词", "泪眸人", "花雨黯", "墨离尘", "倾城恋", "夏洛葵", "花左杀", "殇璃泣", "紫苍炎",
    "青衫隐", "花几叠", "梦冥光", "明月归", "枯叶蝶", "白龙马", "梦里花", "眉间学", "微梦雨", "桐夏花", "末言雨", "度年华", "寒栀冬", "雾透散",
    "一场空", "待君归", "℡君千殇╮", "枫叶羽", "花不语", "花却雨", "寄长风", "离清歌", "撞了怀", "冷风浮", "断秋风", "说书人", "沧古烟", "鹤词归",
    "木鸢歌", "雪霓裳", "回首望", "荟幽兰", "残风葬", "陌浮生", "懵蓝初梦", "只吃糖果", "萌音小软妹", "沁梦雪飞", "单纯美妞", "懵蓝初梦", "玛丽莲萌鹿",
    "冰箱软妹贴", "晴栀", "樱柚少女", "雾月凌", "鹿八岁", "魔法少女喵", "衣上雪花", "苏果孓て", "北栀海", "荼蘼微香", "梦的微尘", "爱情已剧终", "萌音草莓",
    "可爱鬼", "╰苏染", "身高两米八", "倾听雨落", "满树樱花", "娇俏", "逆夏时光", "露露酱", "寂寞的恋人", "海风少女", "素颜如水", "风中雨荷", "青梅染雪",
    "果酱╰", "端木幽凝", "落寞式暧昧", "余生浅末", "繁华旳梦境", "夏日薄雪", "喵幼", "雪舞天使", "粉粉的人儿", "可萝可御", "蓝冰妖妖", "呆萌妞i",
    "可爱￠草莓", "茽年无夏", "南栀兮浅", "淡雅青莲", "花开即故山", "素衫挽玉", "星雪伊人", "乖乖长大", "软声猫へ叫", "—萌娃子—", "夏末烟雨",
    "可爱兔", "枯叶蝶", "我迷了鹿。", "一澜冬雪", "北慕城南", "蝶舞櫻婲落", "凉秋瑾言", "花心小宝宝", "粉色の甜心", "汐聆雪", "约定の幸福",
    "枫韵紫秋", "凌波痕湮逝", "有点点萌", "洛清寒", "我萌你丑", "快乐的孩子", "雪殇若兮", "乖乖女゜", "水染的天色", "败给少女梦", "贝兰萌雪",
    "秦淮兮陌", "熊嘟嘟", "寒玉簪秋水", "慕雪桑", "背影都可爱", "萌得掉渣", "彼岸星光ぐ", "塔塔猫", "草莓味的风", "樱莎美子°", "几缕清葭",
    "疏蕊幽香", "水洗晴空", "似慵懒怪猫", "颜洛殇", "°疯菇凉℡", "紫樱千夏", "吱吱兔", "梦幻之露", "南岸清栀", "馨彤", "如烟绕梁", "如何继续",
    "软可啾", "小妹是萌比", "暖心萌i", "夏末未了°", "软果儿", "软味萝莉", "古巷幼猫", "指尖凝香", "藯蓝づ枫叶", "脚踩棉花糖", "夜舞↘倾城",
    "浪漫果味", "萌萌妈", "静听忧伤", "℡大懒猫ヾ", "流烟凝眸", "蓝萱薇", "蓝雨希", "葡萄不淘", "青春微凉丶", "梦里水香", "超喜欢你", "软妹属性",
    "草莓味的吻", "浅草带疏烟", "一季花开ˇ", "温馨玫瑰", "琉璃水色", "你要宠的神", "棉花和糖", "琉璃娃娃", "梦幻芭比裙", "葵兔", "夏末的晨曦",
    "吱吱兔", "冷凝寒", "萌音小软妹", "野荷的香馥", "十指相扣☆", "栖迟", "草莓姐姐", "森屿海巷", "孤雪傲梅", "漫漫灬滴云", "悦悦超乖", "茶丸软卷",
    "长耳朵兔", "芳草碧色", "澈叶蓝凌", "粉色回忆录", "橘味果酱", "安陵忻美", "北城九命猫", "月色降人间", "酷到没对象", "月牙弯弯", "一缕轻烟", "田如花",
    "诗诗雪汐", "鹿家女王", "别扭的可爱", "国民软妹υ", "简以时光", "萌界大人物", "♀棒℡棒糖", "依恋月光", "冷鸢", "梨涡浅浅", "花痴果果", "风软_江水",
    "品茗听雪", "紫月星梦", "疯丫头", "云朵有点甜", "城堡梦", "没昵称", "重拾女人心", "紫醉琴缘", "零乳糖软妹", "袖染墨凉", "兔兔丢了", "萌耳尐女）",
    "疏星淡月", "冰依湄凌", "雨薇ヤ之恋", "萌动莼喵", "萝莉小熊", "绿柠萌岁月", "懵逼正气", "彼岸の蝴蝶", "紫色蒲公英", "兔牙萝莉", "夏温存∽", "狐狸般可爱",
    "熊妹妹", "暖南绿倾", "斜了霁月", "乖乖小屁孩", "草莓棉花糖", "醉雨苦竹泪", "幻血凰", "爆炸小公举", "雪绒花", "懒懒的感觉", "萝莉教头", "离雨弥港",
    "南顾生烟", "萌雪甜心", "仙味娇娘", "風月佳人", "氙气萝莉", "薇颜浅笑", "作死小达人", "陌上吟归雪", "触摸阳光", "侥幸", "乖神", "粉爪", "花开宿语",
    "雪舞飘零", "暖夏清风", "飞天小仙女", "萌萌妈", "梓夏南街", "旺仔小樱桃", "绿妹妹", "一身可爱风", "北海森屿", "崽崽酱", "沁水百合", "烟雨梦兮",
    "寒艳幽香", "冷蝶霜", "月下唱离", "猫公主殿下", "呆萌少女心", "盛夏尉蓝", "彩色的云", "宅久天然呆", "板凳儿喵儿", "柠檬花扣", "花￣很美", "软酱萌音",
    "差一点笑了", "年的老可爱", "中二傲娇萌", "怪兽哪里跑", "甜的很正经", "浅忆流年", "水晶娃娃", "浅夏丿初晴", "末日的美", "橘虞初梦", "缇娜小公主",
    "萌の妖姬", "可爱の仙女", "萌癌病娇", "喵喵唔><", "萌癌病娇", "花开夏落", "淡墨余香", "徒增可悲", "橘子味的猫", "傲雪清雅", "云淡月浅", "夏日梧桐雨",
    "萝莉小熊", "夏唯夜", "卿极可爱矣", "流年易碎", "夏花流年", "糖丶小妖", "一世琴缘", "千古幽兰", "依梦水欣", "绛雪红唇", "北船余香", "月亮邮递员", "璎婲",
    "奶味少女", "麦兜响当当", "红薇染露", "沫茶", "过气软妹", "黛色若梦", "薄荷蓝", "三千繁花泪", "我的兔兔", "转眼陌泪", "雪季伤幽", "陌若惜", "暖南倾绿",
    "奶气缠身", "蓝色梦幻", "森陌夏栀", "萌公主", "小熊丢了", "赢可是感情", "你的小朋友", "莳光浅逝", "半世晨晓", "夏初凉", "浅笑↘盈盈", "南巷孤猫", "紫馨月",
    "夕颜若雪", "傲性小仙女", "汐雪凝蓝", "心思爆甜", "蝶霜飞", "白色季节", "牵手夕阳", "萌音小软妹", "污萌少女", "橘南小巷", "趴兔", "゛倾城乱世",
    "小白兔", "秋声夜雨", "懵柠未迟", "邪性洒脱", "雨苏梦", "飘扬飞雪", "清颜折笙", "半枝花开", "潘豆豆", "安城如沫", "乖乖小屁孩", "暮色年華", "心雨秋",
    "浅墨惜晨", "喵咕酱", "柠檬味仙女", "雨淋茜屾", "一身可爱味", "浅浅の爱", "夏末的晨曦", "水清浅蓝", "思萌", "丢了温柔", "茉莉蜜茶╮", "雪蝶宿秋风",
    "水洗晴空", "草莓软妹", "阳光一夏", "汐云泪", "迭失过往", "僵尸小可爱", "凝安", "緘默的等候", "怪兽哪里跑", "妃夕妍雪", "柠檬叶子", "清风霁月",
    "灭世小魔女", "粉红心事", "旧巷望雨", "沐叶清风", "女主芊芊", "萝莉泡泡浴", "藍調", "青柠之夏", "尐女杀人魔", "蓝蝶冰魂", "愛上╮寂寞", "無ヌ葬",
    "呆萌妞i", "小奶包", "ˇ花语", "陌離", "鼻涕妞妞", "雪花蝶霜", "积攒一身酷", "时空幽梦", "浅雪陌凉", "蜜棕马尾", "繪夢", "玉雪霓裳", "令若馨",
    "乱世＆宠儿", "萝莉小熊", "盛夏尉蓝", "伊人淡雅泪", "呆傻萌", "凡若尘曦", "沐晴", "_悲喜自渡〆", "_菇凉z", "_胡治宇?", "_泡麵姐姐_",
    "_请叫我锺先森", "_睡觉大王.", "_未命名丶", "_颜子", "_洋_", "_中意"
]