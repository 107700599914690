import store from "@/store";
export const audioPlay = (id) => {
    let timer;
    let audio = document.getElementById(id);
    audio.addEventListener('ended', function () {
        audio.load(); // 重新加载音频
    });

    audio.addEventListener('pause', function () {
        // 暂停后重新加载音频
        audio.load(); // 重新加载音频
    });

    return {
        play: async () => {
            // 设置一个定时器
            if (store.state.userInfo.soundEffects === '1' || !store.state.userClick) return;
            try {
                await audio.play()
            } catch (e) {

            }
        },
        pause: async () => {
            try {
                await audio.pause();
            } catch (e) {
                // alert('异常pause')
            }
        },
        load: async () => {
            try {
                await audio.load();
            } catch (e) {
                // alert('异常load')
            }
        }
    };
}

//引入资源
export const audioResources = [
    {
        id:"audioMouseOver",//鼠标移入音效
        src:require('@/assets/audio/mouseOver.mp3'),
        volume:1,//设置音量大小
    },
    {
        id:"audioError",//错误提示音效
        src:require('@/assets/audio/error.mp3'),
        volume:0.3,
    },
    {
        id:"audioOpenBox1",//老虎机音效1
        src:require('@/assets/audio/open1.mp3'),
        volume:1,
    },
    {
        id:"audioOpenBox2",//老虎机音效2，不带弹窗
        src:require('@/assets/audio/open2.mp3'),
        volume:1,
    },
    {
        id:"audioOpenStart",//有点像追梦那个进度条？
        src:require('@/assets/audio/openStart.mp3'),
        volume:1,
    },
    {
        id:"audioDecompose",//分解装备音效
        src:require('@/assets/audio/decompose.aac'),
        volume:1,
    },
    {
        id:"audioBz",//爆炸音效
        src:require('@/assets/audio/bz.mp3'),
        volume:1,
    },
    {
        id:"audioYanhua",//烟花音效
        src:require('@/assets/audio/yanhua.mp3'),
        volume:1,
    },
]


export const audioList = {
    mouseover:'audioMouseOver',
    error:"audioError",
    openBox1:"audioOpenBox1",
    openBox2:"audioOpenBox2",
    bz:'audioBz',
    decompose:"audioDecompose",
    openStart:"audioOpenStart",
    yanhua:"audioYanhua"
}