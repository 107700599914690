<!--
时间:2023/8/15/015
用法:右侧导航栏
-->

<template>
  <div class='rightNav' id="rightNav">
    <div class="left">
      <div class="returnButton" @click="  back()">
        回到<br/>顶部
      </div>
    </div>
    <div class="right">


    </div>
  </div>
</template>

<script>
import {mapMutations} from "vuex";
import {mapState} from "vuex";
import MenuList from "@/layout/MenuList.vue";

export default {
  name: "RightNav",
  provide() {
    return {
      cdkShowFun: this.cdkShowFun,
      showDownload: this.showDownload,
      openQQ: this.openQQ,
    };
  },
  computed: {
    ...mapState(['userInfo', 'getByUrl']),
  },
  components: { MenuList,},
  data() {
    return {

    }
  },
  methods: {
    ...mapMutations(['setLoginShow', 'setCDK', 'setLoginShow', 'setActiveShow']),
    back() {
      window.scrollTo(0, 0);
    },
  },
  mounted() {
    window.addEventListener('click', this.closeMenu)
  },
  beforeUnmount() {
    window.removeEventListener('click', this.closeMenu)
  },

}
</script>

<style scoped lang="scss">
.rightNav {
  position: fixed;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  //background-color: rgba(51, 31, 88, 0.96);
  width: 2.83rem;
  z-index: 8;
  transition: transform 0.3s;

  .returnButton{
    width: 40px;
    height: 40px;
    background-color: #ff5b5b;
    border-radius: 50%;
    font-size: 12px;
    @include center;
    @include action;

  }
}

@media screen and (max-width: $windowSize-md) {
  .headerNav {
    padding: 0 0.8rem;

  }
}
</style>