<template>
  <!--  错误音效-->
  <audio v-for="(item,index) in audioResources"
         :key="index" :id="item.id"
         :volume="item.volume">
    <source :src='item.src' type="audio/mpeg">
  </audio>
  <router-view class="routerView">

  </router-view>
  <!--  网站背景图片-->
<!--  <div class="commonBg">-->
<!--    <img class="conentBg _windows"-->
<!--         v-if="this.commonbg.includes(this.$route.path)"-->
<!--         :src="require('./assets/common/commonbg.png')"-->
<!--         alt="">-->
<!--    <img class="conentMBg _mobile"-->
<!--         v-if="this.commonbg.includes(this.$route.path)"-->
<!--         :src="require('./assets/common/commonbgh5.jpg')"-->
<!--         alt="">-->
<!--  </div>-->
  <!-- 活动大厅弹窗-->
</template>

<script>
//打开控制台无限debugger
// import './common/debugger'
import {defaultLanguage, remConfig} from "@/common/config";
import Vconsole from "vconsole";
import {audioResources} from "@/common/audio";
import {mapMutations} from "vuex";
import {splitUrl} from "@/utils/math";
import {replaceSkinNameWithMarketHashName} from "@/axios/httpUtil";
import {getByUrl} from "@/axios/auth";

// new Vconsole()

// 加入百度统计
/*var _hmt = _hmt || [];
(function() {
  var hm = document.createElement("script");
  hm.src = "https://hm.baidu.com/hm.js?您的站点ID";
  var s = document.getElementsByTagName("script")[0];
  s.parentNode.insertBefore(hm, s);
})();*/


export default {
  name: "App",
  watch: {
    $route(newval, oldval) {
      if (newval.path === '/jump') {
        this.$socket.send('inClimb')
      }
      if (oldval.path === '/jump') {
        this.$socket.send('outClimb')
      }
    }
  },
  data() {
    return {
      audioResources,
      commonbg: ['/homes', '/'],
      isLogin: localStorage.getItem('token')
    }
  },
  methods: {
    ...mapMutations(['userClick']),
    socketData(data) {
      this.socketSwitch(data)
    },
    initSocket() {
      let len = 32;
      let chars = 'ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678';
      let maxPos = chars.length;
      let key = '';
      for (var i = 0; i < len; i++) {
        key += chars.charAt(Math.floor(Math.random() * maxPos));
      }
      var token = window.localStorage.getItem("token") || '';

      const url = `${process.env.VUE_APP_WSS}/server/${key}/${token || null}`;//ws地址,这里加入自己的地址即可
      // console.log("websocket",url)
      // const url = `${process.env.VUE_APP_BASE_SOCKET}/server/${key}/${token || null}`;//ws地址,这里加入自己的地址即可
      if (token) {
        this.$socket.connect(url, token)
        this.$socket.registerCallBack(this.socketData)
      }
    },
    socketSwitch(data) {
      replaceSkinNameWithMarketHashName(data.data, localStorage.getItem('language') || defaultLanguage)
      switch (data.type) {
        case 'shutDownUser':
          this.$Msg.warn('异地登陆')
          break;
        case 'CreateBattleRoom':
          this.$store.dispatch('actionCreatBattle', data.data)
          break;
        case 'JoinBattleRoom':
          this.$store.dispatch('actionJoinBattle', data.data)
          break;
        case 'countChangeQueue':
          this.$store.dispatch('actionCountChangeQueue', data.data)
          break;
        case 'rollEnd':
          this.$store.dispatch('actionRollEnd', data.data)
          break;
        case 'recharge':
          //加送奖励延迟到账
          setTimeout(() => {
            window.location.reload()
          }, 5000)
          break;
        case 'createClimb':
          this.$store.commit("setCurJump", {users: [], ...data.obj});
          break;
        case 'joinClimb':
          let curJump = JSON.parse(JSON.stringify(this.$store.state.curJump))
          curJump.users.push({state: 0, ...data.obj})
          this.$store.commit("setCurJump", curJump)
          break;
        case 'climbResult':
          data.data.users.forEach(item => item.state = 0)
          this.$store.commit("setCurJump", data.data);
          break;
      }
    },
    userAgent() {
      const userAgent = navigator.userAgent;
      const isAppleDevice = /iPhone|iPad|iPod/i.test(userAgent);

      if (isAppleDevice) {
        // 这是一个苹果设备
        document.documentElement.style.setProperty('--mobil-tabbar-height', '6.08rem');
        document.documentElement.style.setProperty('--mobil-tabbar-padding-bottom', '1.5rem');
      } else {
        // 这不是一个苹果设备
        document.documentElement.style.setProperty('--mobil-tabbar-height', '4.58rem');
        document.documentElement.style.setProperty('--mobil-tabbar-padding-bottom', '0');
      }
    },
    async getByUrl() {
      try {
        const params = {
          url: location.hostname
        }
        let data = await getByUrl(params)
        //将备案号及APP下载链接存储到vuex
        this.$store.commit("setByUrl", data)
        let urlParams = JSON.parse(localStorage.getItem('urlParams')) || {}
        if (!urlParams.invitationCode) urlParams.invitationCode = data.code
        if (data.bdNum) {
          console.log("bdNum")
          var hm = document.createElement("script");
          hm.src = "https://hm.baidu.com/hm.js?" + data.bdNum;
          var s = document.getElementsByTagName("script")[0];
          s.parentNode.insertBefore(hm, s);
        }
        localStorage.setItem('urlParams', JSON.stringify(urlParams))
      } catch (e) {
        console.log(e)
      }
    },

  },
  async created() {
    this.$store.dispatch('actionUserInfo')
    localStorage.removeItem('urlParams')
    let session = JSON.parse(localStorage.getItem('urlParams')) || {}
    let params = splitUrl(window.location.href)
    params = {...session, ...params}
    //百度推广码
    if (params.bd_vid) {
      params.bdVid = params.bd_vid
      delete params.bd_vid
    }
    //搜狗推广码
    if (params.sg_vid) {
      params.sgVid = params.sg_vid
      delete params.sg_vid
    }
    if (params) {
      localStorage.setItem('urlParams', JSON.stringify(params))
    }
    this.userAgent()
    this.initSocket()
    let click = () => {
      //设置用户点击了才播放声音
      this.userClick(true)
      window.removeEventListener('click', click)
    }
    window.addEventListener('click', click)
    await this.getByUrl()
  },
  mounted() {
    function resize() {
      const {configWidth} = remConfig
      const iw = document.documentElement.clientWidth;
      const html = document.querySelector("html");
      let size;
      for (const config of configWidth) {
        if (iw >= config.windowWidth && config.windowWidth !== 0) {
          size = config.fontSize;
          break;
        } else if (config.windowWidth === 0) {
          size = ((iw / config.designWidth) * config.fontSize);
        }
      }
      html.style.fontSize = size + "px";
    }

    resize()
    window.onresize = resize
    //首次打开，加载动画
    // document.getElementById('loading').remove()
  },
}
</script>

<style lang="scss">
@import "~@/style/index.scss";

:root {
  --window-headerNav-height: 8rem;
  --window-leftNav-width: 4.9rem;
  --window-footer-height: 16rem;
  --window-swiper-height: 5.4rem;
  --mobil-tabbar-height: 4.58rem;
  --mobil-tabbar-padding-bottom: 0;
  --window-width: 60rem;
  --window-fontSize: 0.7rem;
}


//引入本地字体
/*@font-face {
  font-family: "Cambria";
  src: url("~@/assets/font/Cambria.ttf");
  font-weight: normal;
  font-style: normal;
}*/

html,
body,
#app,
{
  scroll-behavior: smooth;
  font-size: 0.67rem;
  color: $font-color1;
  @media screen and (max-width: $windowSize-md) {
    font-size: 1.08rem;
  }
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  //overflow: hidden;
}


.commonBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100%;
  z-index: -999;
  background-color: $content-background;

  .conentBg {
    width: 100%;
    /*height: 100%;*/
    min-height: 100vh;
    min-width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
  }
}

@media screen and (max-width: $windowSize-md) {
  .conentMBg {
    width: 100%;
    /*height: 100%;*/
    min-height: 100vh;
    min-width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
  }
}

</style>
