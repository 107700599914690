<!--
时间:2023/8/30/030
用法:底部布局-->

<template>
  <div class='footer'>
    <!--在线统计-->
    <!-- 底部帮助-->
    <div class="copyright">
      <div class="problem">
        <div class="problemItem"
             @click="goPath(item.path)"
             v-for="(item,index) in problem" :key="index">
          {{ $t(item.name) }}
        </div>
      </div>
      <div class="partners">
        <img v-for="(item,index) in partners"
             :key="index"
             :src="item.img" alt="">
      </div>
      <p class="copy">{{ extInfoJson?.beian}}</p>
      <p class="copy">{{ extInfoJson?.zhuti}}</p>
    </div>


  </div>
</template>

<script>

import {getStatistics} from "@/axios/homes";
import {mapState} from "vuex";
import {goPath} from "@/router/goPath";
import {getByUrl} from "@/axios/auth";

export default {
  name: "Footer",
  computed: {
    ...mapState(['countChangeQueue'])
  },
  watch: {
    countChangeQueue: {
      handler(newVal) {
        // console.log(newVal,'在线统计')
        this.statistical[0].val = newVal.openCount
        this.statistical[1].val = newVal.userCount
        this.statistical[2].val = newVal.onlineCount
      },
      deep: false
    }
  },
  data() {
    return {
      statistical: [
        {
          name: 'openCases',
          val: 0,
          img: require('../assets/icon/open.png')
        },
        {
          name: 'users',
          val: 0,
          img: require('../assets/icon/users@2x.png')
        },
        {
          name: 'online',
          val: 0,
          img: require('../assets/icon/online@2x.png')
        },
      ],
      shareList: [
        {
          img: require('../assets/icon/facebook.png'),
          path: ''
        },
        {
          img: require('../assets/icon/Twitter.png'),
          path: ''
        },
        {
          img: require('../assets/icon/discord.png'),
          path: ''
        },
        {
          img: require('../assets/icon/telegram.png'),
          path: ''
        },
      ],
      partners: [
        {
          img: require('../assets/icon/zfbzf.png')
        },
        {
          img: require('../assets/icon/wxzf.png')
        },
        {
          img: require('../assets/icon/sf.png')
        },
        {
          img: require('../assets/icon/kx.png')
        },
      ],
      problem: [
        {
          name: "nav.usrAgreement",
          path: '/rule/usrAgreement'
        },
        {
          name: "nav.privacyAgreement",
          path: '/rule/privacyAgreement'
        },
        {
          name: "nav.questions",
          path: '/rule/questions'
        },
        {
          name: "nav.aboutUs",
          path: '/rule/aboutUs'
        },
      ],
      statistics: {},
      extInfoJson:{}
    }
  },
  methods: {
    goPath,
    async getStatistics() {
      try {
        let data = await getStatistics()
        this.statistics = data
        this.statistical[0].val = data.openCount
        this.statistical[1].val = data.userCount
        this.statistical[2].val = data.onlineCount
        // console.log("在线人数", this.statistical, data)
      } catch (e) {
        console.log(e)
      }
    },
    jump(path) {
      if (!path) return this.$Msg.warn(this.$t('confirm.notYetOpn'))
      window.open(path)
    },
    async getByUrl() {
      try {
        const params = {
          url:location.hostname
        }
        let data = await getByUrl(params)
        this.extInfoJson = data.extInfoJson
      } catch (e) {
        console.log(e)
      }
    },
  },
  mounted() {
    this.getStatistics()
    this.getByUrl()
  }
}
</script>

<style scoped lang="scss">
.footer {
  height: auto;
  background-color: #1C1E29;

  .statistical {
    height: 5.416rem;
    padding: 0 1rem;
    background: url("../assets/home/bj2@2x.png") no-repeat center/cover;
    @include center;

    .statisticalBox {
      width: 62.5rem;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;

      .statisticalItem {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        text-align: left;

        .left {
          img {
            width: 2.58rem;
            margin-right: 1.04rem;
          }
        }

        .right {
          text-align: left;

          .val {
            font-size: 1.15rem;
            font-weight: 800;
            color: #CBCCD6;
          }

          .name {
            font-size: 0.68rem;
            font-weight: 500;
            color: #484A5A;
          }
        }
      }
    }

  }

  .copyright {
    padding: 2.1rem 2rem 2.9rem 2rem;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;

    .shareList {
      .item {
        width: 1.5rem;
        margin: 0 0.33rem;
        cursor: pointer;
      }
    }

    .problem {
      @include center;
      margin: 0.66rem 0;
      flex-wrap: wrap;
      //text-decoration: underline;

      .problemItem {
        margin: 0 0.33rem;
        cursor: pointer;
        @include transition(color);
        &:hover {
          color: $theme-color2;
        }
      }
    }
    .partners {
      margin-bottom: 0.66rem;

      img {
        margin:  0.33rem;
        height: 1.75rem;
      }

    }

    .copy {

      color: #494B5B;
    }

  }

  @media screen and (max-width: $windowSize-md) {
    .copyright {
      padding: 2rem;

      .shareList {
        .item {
          width: 2.5rem;
          margin: 0 0.66rem;
        }
      }

      .problem {
        @include center;
        margin: 1rem 0;

        .problemItem {
          margin: 0 0.33rem;
          cursor: pointer;

          &:hover {
            color: #FBC600;
          }
        }
      }
      .partners {
        margin-bottom: 0.66rem;

        img {
          margin: 0.66rem;
          height: 1.75rem;
        }

      }

      .copy {

        color: #494B5B;
      }

    }

  }
}
</style>
